import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDashboard4Card, updateDashboardDetails } from "../../../redux/dashboardSlice";
import DashboardCard from "./DashboardCard";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { BsFullscreen, BsZoomIn, BsZoomOut } from "react-icons/bs";

export const Dashboard4Board = ({ setCardModal, editable = false, loadData }) => {
  const dragRef = useRef();
  const dispatch = useDispatch();
  const { backgroundImage, cards } = useSelector((state) => state.dashboard[4]);

  const [imageWidth, setImageWidth] = useState();
  const [imageHeight, setImageHeight] = useState();
  const [isMoveable, setIsMoveable] = useState(false);

  const removeBackground = () => {
    dispatch(updateDashboardDetails({ dashboardType: 4, backgroundImage: null }));
  };

  return (
    <div className="position-relative">
      <TransformWrapper
        wheel={{ disabled: true }}
        initialScale={1}
        centerZoomedOut
        centerOnInit
        minScale={0.5}
        maxScale={3}
        disabled={isMoveable}
        initialPositionX={100}
      >
        {({ zoomIn, zoomOut, resetTransform, centerView }) => {
          return (
            <>
              <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
                <div
                  className="notSelect position-relative"
                  style={{ backgroundColor: Boolean(backgroundImage) ? " transparent" : "#e3e2e7", height: "auto" }}
                >
                  {cards?.map((item) => (
                    <DashboardCard
                      editable={editable}
                      key={item._id}
                      dragRef={dragRef}
                      item={item}
                      updatePosition={(id, { position }) => {
                        // const xPercentage = (position.x * 100) / imageWidth;
                        // const yPercentage = (position.y * 100) / imageHeight;
                        console.log(id, position);

                        dispatch(updateDashboard4Card({ _id: id, position }));
                      }}
                      deleteItem={(id) => {
                        const remain = cards.filter((entry) => entry._id !== id);
                        // setData(remain);
                        dispatch(updateDashboardDetails({ dashboardType: 4, cards: remain }));
                      }}
                      editItem={(item) => {
                        setCardModal(item);
                      }}
                      loadData={loadData}
                      dragStart={() => {
                        setIsMoveable(true);
                      }}
                      dragEnd={() => {
                        setIsMoveable(false);
                      }}
                    />
                  ))}
                  <div>
                    {editable && (
                      <span
                        onClick={removeBackground}
                        className="position-absolute translate-middle badge border border-light rounded-circle bg-danger p-2"
                        style={{ zIndex: 9, top: 25, right: 0 }}
                      >
                        X <span className="visually-hidden">unread messages</span>
                      </span>
                    )}
                    <img
                      src={backgroundImage}
                      alt="Dashboard BG"
                      className="notSelect"
                      style={{
                        width: imageWidth,
                        height: imageHeight,
                      }}
                      onLoad={(e) => {
                        centerView();
                        setImageWidth(e.currentTarget.naturalWidth);
                        setImageHeight(e.currentTarget.naturalHeight);
                      }}
                    />
                  </div>
                </div>
              </TransformComponent>

              <div
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 0,
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                }}
              >
                <button
                  onClick={() => {
                    resetTransform();
                  }}
                  className="px-2 btn btn-dark"
                >
                  <BsFullscreen />
                </button>
                <button
                  onClick={() => {
                    zoomIn();
                  }}
                  className="px-2 btn btn-dark"
                >
                  <BsZoomIn />
                </button>
                <button
                  onClick={() => {
                    zoomOut();
                  }}
                  className="px-2 btn btn-dark"
                >
                  <BsZoomOut />
                </button>
              </div>
            </>
          );
        }}
      </TransformWrapper>
    </div>
  );
};
