import React from "react";
import { Outlet } from "react-router-dom";

import SettingSidebarNav from "../../components/Settings/SettingSidebarNav";

const Settings = () => {
  return (
    <div className="settings">
      <div className="container-fluid">
        <div className="row my-5 vh60">
          <div className="col-md-2">
            <SettingSidebarNav />
          </div>

          <div className="col-md-10">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
