import React from "react";
import { Col, Row } from "react-bootstrap";
import { GenericInput } from "../../../components/Shared/GenericInput";
import { GenericSelect } from "../../../components/Shared/GenericSelect";

export const GeneralSettings = ({ dashboardDetails, handleChangeTemplate }) => {
  return (
    <>
      <Row>
        <Col md={4} xxl={3}>
          <GenericInput
            label="Name"
            name="name"
            handleInput={handleChangeTemplate}
            value={dashboardDetails?.name}
            required={true}
            wrapperClassName="mb-3"
            placeholder="Enter template name"
          />
        </Col>
        <Col md={4} xxl={3}>
          <GenericSelect
            label="User Type"
            name="userType"
            required={true}
            options={userTypeRoles}
            value={dashboardDetails?.userType}
            handleInput={handleChangeTemplate}
            placeholder="Select User Type"
          />
        </Col>
      </Row>
    </>
  );
};

const userTypeRoles = [
  { value: "all", name: "All System Users" },
  { value: "superAdmin", name: "Super Admin Only" },
];
