import { Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { api, endpoint } from "../../../api";
import { CreateSystemParameter } from "../../../components/Settings/CreateSystemParameter";
import { userRole } from "../../../constant";
import ParametersTable from "./ParametersTable";

const Parameters = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [parameters, setParameters] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  const userDetails = useSelector((state) => state.user.userDetails);

  const getParameters = async () => {
    setListLoading(true);
    try {
      let { results, count } = await api.get(endpoint.get_system_parameter_list, {
        params: { limit, page, sortBy: "createdAt", orderBy: "DESC" },
      });
      results = results.map((item, idx) => {
        item.sl = (page - 1) * limit + idx + 1;
        return item;
      });
      setParameters(results);
      setTotal(count || 0);
    } catch (error) {}
    setListLoading(false);
  };

  useEffect(() => {
    document.title = "SEDA - Manage System Parameter";
    getParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  const isCreatePortionVisible =
    userDetails.role === userRole.superAdmin ||
    userDetails.role === userRole.installer ||
    userDetails.role === userRole.admin;

  return (
    <div className="card p-3 mb-3">
      <h3>Manage System Parameter</h3>
      <div className="row mt-4">
        {isCreatePortionVisible ? <CreateSystemParameter getParameters={getParameters} /> : ""}
        <div className={`col-md-${isCreatePortionVisible ? "8" : "12"}`}>
          <Spin spinning={listLoading}>
            <ParametersTable
              listLoading={listLoading}
              data={parameters}
              getParameters={getParameters}
              limit={limit}
              total={total}
              setPage={setPage}
              setLimit={setLimit}
            />
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default Parameters;
