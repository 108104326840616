import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { endpoint } from "../../api";
import NotificationSidebar from "../../components/Notifications/NotificationSidebar";
import RecipientListTable from "./RecipientListTable";

const RecipientList = () => {
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState();
  const [ErrorMessage, setErrorMessage] = useState();
  const [siteLocations, setSiteLocations] = useState([]);
  const [assignedAlarm, setAssignedAlarm] = useState([]);
  const [users, setUsers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [notificationsEx, setNotificationsEx] = useState([]);
  const [assignAlerm, setAssignAlarm] = useState({
    isActive: true,
  });
  const [AssignAlarmEx, setAssignAlarmEx] = useState({
    isActive: true,
  });

  console.log({ assignAlerm });
  const onInputChangeEx = (e) => {
    setAssignAlarmEx({ ...AssignAlarmEx, [e.target.name]: e.target.value });
  };
  const onInputChange = (e) => {
    setAssignAlarm({ ...assignAlerm, [e.target.name]: e.target.value });
  };
  const handleUserChange = (e) => {
    let user = users.filter((item) => item._id === e.target.value);
    setAssignAlarm({
      ...assignAlerm,
      user: e.target.value,
      name: user[0]?.name,
      email: user[0]?.email,
    });
  };

  const getSiteLocations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/site-location/my-site`, {
        withCredentials: true,
      });
      setSiteLocations(response.data);
    } catch (error) {}
  };
  const getNotifications = async () => {
    axios
      .get(endpoint.notification, {
        params: { site: assignAlerm.site, page: 1, limit: 99999 },
        withCredentials: true,
      })
      .then((res) => setNotifications(res.data?.results));
  };
  useEffect(() => {
    if (assignAlerm.site) {
      getNotifications();
    }
    // eslint-disable-next-line
  }, [assignAlerm.site]);

  const getNotificationsEx = async () => {
    axios
      .get(endpoint.notification, {
        params: { site: AssignAlarmEx.site, page: 1, limit: 99999 },
        withCredentials: true,
      })
      .then((res) => setNotificationsEx(res.data?.results));
  };
  useEffect(() => {
    if (AssignAlarmEx.site) {
      getNotificationsEx();
    }
    // eslint-disable-next-line
  }, [AssignAlarmEx.site]);
  // console.log({ assignAlerm });
  const getUsers = async () => {
    try {
      const response = await axios.get(`/user/siteUser/${assignAlerm.site}/${assignAlerm?.role}`, {
        withCredentials: true,
      });
      if (response) {
        setUsers(response.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (assignAlerm?.role === "external") {
    } else {
      getUsers();
    }
    // eslint-disable-next-line
  }, [assignAlerm.site, assignAlerm?.role]);

  const getAssignedAlarm = async () => {
    const response = await axios.get(`${endpoint.notification_v1}/assign`, { withCredentials: true });
    if (response) {
      setAssignedAlarm(response.data);
    }
  };
  useEffect(() => {
    document.title = "SEDA - Recipient List";
    getAssignedAlarm();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getSiteLocations(userDetails);
    // eslint-disable-next-line
  }, [userDetails]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`/notification/assign`, assignAlerm, {
        withCredentials: true,
      });
      if (response) {
        setSuccessMessage("Alarm Assign Successfully");
        getAssignedAlarm();
        setAssignAlarm({
          name: "",
          email: "",
          role: "",
          site: "",
          alarm: "",
          user: "",
          isActive: true,
        });
        setTimeout(() => {
          setSuccessMessage();
          // window.location.reload(false);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleSubmitEx = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`/notification/assign`, AssignAlarmEx, {
        withCredentials: true,
      });
      if (response) {
        setSuccessMessage("Alarm Assign Successfully");
        getAssignedAlarm();
        setAssignAlarmEx({
          name: "",
          email: "",
          role: "external",
          site: "",
          alarm: "",
          isActive: true,
        });
        setTimeout(() => {
          setSuccessMessage();
          // window.location.reload(false);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="settings">
      <div className="container-fluid">
        <div className="row my-5 vh60">
          <div className="col-md-2">
            <NotificationSidebar />
          </div>
          <div className="col-md-10">
            <div className="card p-3 mb-3">
              <div className="row">
                <div className="col-md-6">
                  <h3>Notification Recipient List</h3>
                  <div className="d-flex justify-content-center">
                    {isLoading && <Spinner animation="border" variant="dark" />}
                  </div>
                  {SuccessMessage && (
                    <div className="alert alert-success" role="alert">
                      {SuccessMessage}{" "}
                    </div>
                  )}
                  {ErrorMessage && (
                    <div className="alert alert-warning" role="alert">
                      {ErrorMessage}{" "}
                    </div>
                  )}
                </div>
                <div className="col-md-6 ">
                  <Link to="/alarm-summary" className="btn btn-secondary float-end">
                    Back
                  </Link>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6 border-end">
                  <h5 className="pb-2">Assign Existing System User Accounts</h5>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="site" className="form-label">
                          Site Location
                        </label>
                        <select
                          name="site"
                          id="site"
                          value={assignAlerm?.site}
                          className="form-select"
                          onChange={onInputChange}
                        >
                          <option> Select Site</option>
                          {siteLocations &&
                            siteLocations.length > 0 &&
                            siteLocations.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="role" className="form-label">
                          User Type
                        </label>
                        <select
                          name="role"
                          id="role"
                          value={assignAlerm?.role}
                          className="form-select"
                          onChange={onInputChange}
                        >
                          <option label="Select Role"></option>
                          <option value="admin">Admin</option>
                          <option value="installer">Installer</option>
                          <option value="user">Site User</option>
                          <option value="public">Site Public</option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="user" className="form-label">
                          Site User
                        </label>
                        <select
                          name="user"
                          id="user"
                          className="form-select"
                          onChange={handleUserChange}
                          value={assignAlerm.user}
                        >
                          <option> Select User</option>
                          {users &&
                            users.length > 0 &&
                            users.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <label htmlFor="alarm" className="form-label">
                          Alarm Name
                        </label>
                        <select
                          name="alarm"
                          id="alarm"
                          value={assignAlerm?.alarm || ""}
                          className="form-select"
                          onChange={onInputChange}
                        >
                          <option value={""}> Select Alarm</option>
                          {notifications &&
                            notifications.length > 0 &&
                            notifications.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-8">
                        <button
                          type="submit"
                          className="btn btn-warning mt-4 float-end"
                          disabled={
                            !assignAlerm?.site || !assignAlerm?.user || !assignAlerm?.role || !assignAlerm?.alarm
                          }
                        >
                          Assign
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-6">
                  <h5 className="pb-2">Assign Email Address (External Accounts)</h5>
                  <form onSubmit={handleSubmitEx}>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="site" className="form-label">
                          Site Location
                        </label>
                        <select
                          name="site"
                          id="site"
                          value={AssignAlarmEx?.site}
                          className="form-select"
                          onChange={onInputChangeEx}
                        >
                          <option> Select Site</option>
                          {siteLocations &&
                            siteLocations.length > 0 &&
                            siteLocations.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="name" className="form-label">
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={AssignAlarmEx?.name}
                          placeholder="Enter full name"
                          onChange={onInputChangeEx}
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="email" className="form-label">
                          Email Address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={AssignAlarmEx?.email}
                          placeholder="Enter email"
                          onChange={onInputChangeEx}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <label htmlFor="alarm" className="form-label">
                          Alarm Name
                        </label>
                        <select
                          name="alarm"
                          id="alarm"
                          value={AssignAlarmEx?.alarm || ""}
                          className="form-select"
                          onChange={onInputChangeEx}
                        >
                          <option value={""}> Select Alarm</option>
                          {notificationsEx &&
                            notificationsEx.length > 0 &&
                            notificationsEx.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-8">
                        <button
                          type="submit"
                          className="btn btn-warning mt-4 float-end"
                          disabled={!AssignAlarmEx?.site || !AssignAlarmEx?.name || !AssignAlarmEx?.alarm}
                        >
                          Assign
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <hr />

              <RecipientListTable
                data={assignedAlarm}
                siteLocations={siteLocations}
                getAssignedAlarm={getAssignedAlarm}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipientList;
