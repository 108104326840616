import React, { useContext, useState } from "react";
import DataTable from "react-data-table-component";
import { FiEdit, FiTrash } from "react-icons/fi";
import { BiStar } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import axios from "axios";
import Swal from "sweetalert2";
import { ThemeContext } from "../../App.js";
import { Modal, notification, Spin } from "antd";
import { useEffect } from "react";
import { api } from "../../api/request.js";
import { endpoint } from "../../api/endpoint.js";

const DeviceParameters = ({ isVirtualDevice, isLoading, data, device, getDevice, getDeviceParameters }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [singleDeviceParameter, setSingleDeviceParameter] = useState({});
  const [parameters, setParameters] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  let { isDark } = useContext(ThemeContext);

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
  };
  const getParameters = async () => {
    const response = await axios.get(`/system-parameter`, { withCredentials: true });
    if (response) {
      setParameters(response.data.results);
    }
  };
  useEffect(() => {
    getParameters();
  }, []);

  const columns = [
    {
      name: "No.",
      cell: (_, index) => <div>{(page - 1) * limit + index + 1}</div>,
      selector: (row) => console.log(row),
      width: "60px",
    },
    {
      name: "Device Parameter Name",
      cell: (row) => <div className="text-capitalize">{row?.name}</div>,
      selector: (row) => row?.name,
    },
    ...(isVirtualDevice
      ? [
          {
            name: "Device Name",
            cell: (row) => <div className="text-capitalize">{row?.device?.name}</div>,
          },
        ]
      : []),

    {
      name: "System Parameter Name",
      cell: (row) => <div className="text-capitalize">{row?.systemParameter?.name || "--"}</div>,
      selector: (row) => row?._id,
      // center: true,
    },
    {
      name: "Count",
      selector: (row) => row?.count,
      center: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <button
            className="btn btn-info me-1"
            onClick={() => {
              setIsModalOpen(true);
              setSingleDeviceParameter({
                ...row,
                systemParameterId: row?.systemParameter?._id,
              });
            }}
          >
            <FiEdit />
          </button>
          <button className="btn btn-danger" onClick={() => deleteParameter(row)}>
            <FiTrash />
          </button>
        </div>
      ),
      right: "yes",
    },
  ];

  const deleteParameter = async (parameter) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this parameter?",
      icon: "error",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/device-parameter/${parameter._id}`, {
            withCredentials: true,
          })
          .then((res) => {
            // getDevice();
            getDeviceParameters();
            Swal.fire({
              title: "Done!",
              text: "Parameter Deleted Successfully",
              icon: "success",
              timer: 2000,
              button: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Something went wrong!",
              text: error?.response?.data?.message || error.message,
              icon: "error",
              // timer: 3500,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const onAssingSystemParaToDevicePara = async () => {
    try {
      const { message } = await api.post(endpoint.assing_system_parameter, {
        systemParameterId: singleDeviceParameter?.systemParameterId,
        deviceParameterId: singleDeviceParameter?._id,
      });
      notification.info({
        message,
        placement: "bottomLeft",
      });
      setIsModalOpen(false);
      getDeviceParameters();
    } catch (error) {
      console.log(error?.response);
      notification.warning({
        message: error?.response?.data?.message,
        placement: "bottomLeft",
      });
    }
  };

  return (
    <div>
      <h4 className="mb-3">Available Device Parameters</h4>
      <Modal
        title={`Assign System Parameter Name - ${singleDeviceParameter?.name}`}
        open={isModalOpen}
        onOk={onAssingSystemParaToDevicePara}
        onCancel={handleCancel}
        okButtonProps={{
          className: "btn btn-success h-auto",
          loading,
        }}
        cancelButtonProps={{ className: "btn btn-secondary h-auto" }}
      >
        <select
          className="form-select"
          name="interval"
          value={singleDeviceParameter?.systemParameterId}
          onChange={(e) =>
            setSingleDeviceParameter({
              ...singleDeviceParameter,
              systemParameterId: e.target.value,
            })
          }
        >
          <option value="">Select system parameter</option>
          {parameters.map(({ _id, name }) => (
            <option value={_id} key={_id}>
              {name}
            </option>
          ))}
        </select>
      </Modal>
      <Spin spinning={isLoading}>
        <DataTable
          columns={columns}
          data={data}
          pagination
          striped={!isDark}
          theme={isDark ? "dark" : "light "}
          paginationPerPage={limit}
          onChangePage={(data) => setPage(data)}
          onChangeRowsPerPage={(data) => {
            setLimit(data);
            setPage(1);
          }}
          paginationRowsPerPageOptions={[10, 20, 50]}
        />
      </Spin>
    </div>
  );
};

export default DeviceParameters;
