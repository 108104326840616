import React from 'react';
import { Outlet } from 'react-router-dom';
import SettingSidebarNav from '../../components/Settings/SettingSidebarNav';

const DashboardSettingsHome = () => {
    return (
        <Outlet />
    );
};

export default DashboardSettingsHome;