import { Spin } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { AiOutlineEye } from "react-icons/ai";
import { BiUserX } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { api, endpoint } from "../../api";
import { userRole } from "../../constant";
import moment from "moment";

const AssignDashboard = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [role, setRole] = useState("");
  const [dashboardList, setDashboardList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [assign, setAssign] = useState([]);
  const [dashbaordId, setDashboardId] = useState("");

  const [sites, setSites] = useState([]);
  const [formData, setFormData] = useState({
    siteLocation: "",
    siteUser: "",
    dashboardType: "",
    dashboardId: "",
  });

  // get location
  const getLocation = async () => {
    const response = await axios.get(`/site-location`, { withCredentials: true });
    setLocations(response?.data);
  };

  // get site user
  const getUsers = async () => {
    setIsLoading(true);
    try {
      if (role && formData.siteLocation) {
        const { results } = await api.get(endpoint.all_users, {
          params: {
            sortBy: "createdAt",
            orderBy: "DESC",
            role,
            site: formData.siteLocation,
          },
        });
        setUsers(results);
      }
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  // get dashboard list
  const getDashboardList = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const response = await api.get(endpoint.get_dashboard_multi_list, {
        params: {
          limit: 1000,
          page: 1,
        },
      });
      const dList = response?.results?.filter((item) => item.userType === "all");
      setDashboardList(dList);
      setIsLoading(false);
    }
  };

  const getAssign = async () => {
    setIsLoading(true);
    let { results, count } = await api.get(endpoint.get_dashboard_assinees, {
      params: {
        limit: 10,
        page: 1,
        sortBy: "createdAt",
        orderBy: "DESC",
        dashboardType: formData.dashboardType,
      },
    });
    setAssign(results);
    setTotal(count);
    console.log({ results });
    setIsLoading(false);
  };

  useEffect(() => {
    getLocation();
    getDashboardList();
  }, []);

  // useEffect(() => {
  //   getAssign()
  // }, [formData.dashboardType]);

  useEffect(() => {
    getAssign();
  }, [formData.dashboardId]);

  useEffect(() => {
    getUsers();
  }, [role, formData.siteLocation]);

  const handleChange = (e) => {
    if (e.target.name === "siteLocation") {
      return setFormData({
        ...formData,
        siteUser: "",
        [e.target.name]: e.target.value,
      });
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    setFormData({ ...formData, siteUser: "" });
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await axios.delete(`${endpoint.delete_dashboard_assinee}/${id}`, { withCredentials: true });
        getAssign();
        Swal.fire("Deleted!", "User Unassigned From Dashboard Successfully", "success");
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.dashboardId) {
        return Swal.fire({
          title: "Error",
          text: "Select dashboard first",
          timer: 1500,
          button: false,
        });
      }
      const { message } = await api.post(endpoint.assinee_dashboard, {
        user: formData.siteUser,
        site: formData.siteLocation,
        dashboardType: Number(formData.dashboardType),
        dashboard: formData.dashboardId,
      });
      console.log({ message });
      Swal.fire({
        title: "",
        text: message,
        icon: "success",
        timer: 1500,
        button: false,
      });
      getAssign();
      // setSingleUser({});
    } catch (error) {
      Swal.fire({
        title: "",
        text: "Some error happend",
        icon: "warning",
        timer: 1500,
        button: false,
      });
    }
  };

  const activeHandler = async (isActive, id) => {
    console.log({ isActive, id });
    const response = await axios.put(
      `/dashboard/multiAssign/${id}`,
      { isActive: !isActive },
      { withCredentials: true }
    );
    if (response.status === 200) {
      getAssign();
    }
  };

  const columns = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      width: "60px",
    },
    {
      name: "User Name",
      cell: (row) => row.user?.name,
    },
    {
      name: "Email",
      cell: (row) => row.user?.email,
    },
    {
      name: "User Type",
      cell: (row) => row.user?.role,
    },
    {
      name: "Site",
      cell: (row) => row.site?.name,
    },
    {
      name: "Date Assign",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      center: true,
    },
    {
      name: "Status",
      cell: (row) =>
        Boolean(row.isActive) ? (
          <Button variant="success"> Active </Button>
        ) : (
          <Button variant="danger"> Deactivated </Button>
        ),
      center: true,
    },
    {
      name: "Action",
      width: "220px",
      center: true,
      cell: (row) => (
        <div>
          <Link to={`/users/details/${row.user?._id}`}>
            <Button className="me-2 btn-info">
              <AiOutlineEye className="text-white" />
            </Button>
          </Link>
          {/* EditDashboard */}
          {/* <Button className="me-2" onClick={() => activeHandler(Boolean(row.isActive), row._id)}>
            <BiUserX className="text-white" />
          </Button> */}
          <Button className="me-2 btn-danger" onClick={() => handleDelete(row._id)}>
            <MdDeleteOutline className="text-white" />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="card p-3 mb-3">
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div>
            <h3>Dashboard Settings</h3>
            <h5>Assign Dashboard</h5>
          </div>

          <div>
            <Form.Group className="my-2">
              <Form.Label>Select Dashboard</Form.Label>
              <Form.Select
                required
                className="text-secondary"
                value={formData.dashboardType ? `${formData.dashboardType}:${formData.dashboardId}` : ""}
                onChange={(e) => {
                  const [dashboardType, _id] = e.target.value.split(":");
                  setFormData({
                    ...formData,
                    dashboardType: dashboardType,
                    dashboardId: _id,
                  });
                }}
                name="dashboardType"
              >
                <option value="" disabled>
                  Select dashboard
                </option>
                {dashboardList &&
                  dashboardList.map((item, index) => (
                    <option key={index} value={`${item.dashboardType}:${item._id}`}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </div>
        </div>

        <h6 className="fw-bold mt-4">Assign Existing System User Accounts</h6>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={4}>
              <Form.Group className="my-2">
                <Form.Label>Site Location</Form.Label>
                <Form.Select
                  required
                  className="text-secondary"
                  onChange={handleChange}
                  value={formData.siteLocation}
                  name="siteLocation"
                >
                  <option value="">Select site</option>
                  {locations &&
                    locations.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group className="my-2">
                <Form.Label>User Role</Form.Label>
                <Form.Select
                  required
                  className="text-secondary"
                  onChange={handleRoleChange}
                  value={role}
                  name="userType"
                >
                  <option value="" disabled>
                    Select role
                  </option>
                  {Object.keys(userRole).map((role) =>
                    role === userRole.superAdmin ? (
                      ""
                    ) : (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    )
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group className="my-2">
                <Form.Label>Site User</Form.Label>
                <Form.Select
                  required
                  className="text-secondary"
                  value={formData.siteUser}
                  onChange={handleChange}
                  name="siteUser"
                >
                  <option value="">Select user</option>
                  {users.length > 0 &&
                    users.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            {/* <Col xs={4}>
              <Form.Group className="my-2">
                <Form.Label>Dashboard</Form.Label>
                <Form.Select
                  required
                  className="text-secondary"
                  value={formData.dashboardType ? `${formData.dashboardType}:${formData.dashboardId}` : ""}
                  onChange={(e) => {
                    const [dashboardType, _id] = e.target.value.split(":");
                    setFormData({
                      ...formData,
                      dashboardType: dashboardType,
                      dashboardId: _id,
                    });
                  }}
                  name="dashboardType"
                >
                  <option value="" disabled>
                    Select dashboard
                  </option>
                  {dashboardList &&
                    dashboardList.map((item, index) => (
                      <option key={index} value={`${item.dashboardType}:${item._id}`}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col> */}
          </Row>
          <div className="marginTop" style={{ width: "100%", textAlign: "right" }}>
            <Button type="submit" className="px-4 " variant="success" disabled={!formData.dashboardId}>
              Assign
            </Button>
          </div>
        </Form>
        <hr />
        <div className="mt-4">
          <Spin spinning={isLoading}>
            <DataTable
              columns={columns}
              data={assign}
              pagination
              paginationServer
              // progressPending={isLoading}
              // progressComponent={<Spin />}
              noDataComponent={isLoading ? "Loading" : "No record found."}
              className="mt-3"
              paginationTotalRows={total}
              paginationPerPage={limit}
              onChangePage={(data) => {
                setPage(data);
              }}
              onChangeRowsPerPage={(data) => {
                setLimit(data);
                setPage(1);
              }}
              paginationRowsPerPageOptions={[10, 20, 50]}
            />
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default AssignDashboard;
