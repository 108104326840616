import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import SiteLocationForm from "./SiteLocationForm";

const EditSiteLocation = () => {
  const params = useParams();
  const siteLocationId = params.siteLocationId;

  const [siteLocation, setSiteLocation] = useState();

  const getUser = async () => {
    axios
      .get(`/site-location/` + siteLocationId, {
        withCredentials: true,
      })
      .then((res) => {
        setSiteLocation(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  return <SiteLocationForm mode="edit" data={siteLocation} />;
};

export default EditSiteLocation;
