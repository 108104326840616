import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DeviceForm from "./DeviceForm";

const EditDevice = () => {
  const params = useParams();
  const deviceId = params.deviceId;

  const [data, setData] = useState();

  const getUser = async () => {
    axios
      .get(`/device/` + deviceId, {
        withCredentials: true,
      })
      .then((res) => {
        setData(res.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  return <DeviceForm mode="edit" data={data} />;
};

export default EditDevice;
