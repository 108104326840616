import { Spin } from "antd";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FiEdit, FiTrash } from "react-icons/fi";
import { ThemeContext } from "../../App";
import { FormulaForm } from "../../pages/Settings/Formula/FormulaForm";
import FormulasTable from "../../pages/Settings/Formula/FormulasTable";

export const AssignedDeviceFormula = ({
  deviceFormulas,
  deviceParameters,
  deleteFormula = () => {},
  refetchFormula = () => {},
  isLoading = false,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFormula, setSelectedFormula] = useState();

  const onFinish = () => {
    handleCancelModal();
    refetchFormula();
  };

  const handleEdit = (row) => {
    setSelectedFormula(row);
    setShowModal(true);
  };

  const handleCancelModal = () => {
    setShowModal(false);
    setSelectedFormula();
  };

  return (
    <>
      <h4 className="mt-3">Assigned Device Formulas</h4>

      <Spin spinning={isLoading}>
        <div className="row">
          <FormulasTable
            formulas={deviceFormulas}
            onDeleteRow={(row) => deleteFormula(row._id)}
            onEditRow={handleEdit}
          />
        </div>
      </Spin>
      <Modal
        show={showModal}
        style={{ width: "100%" }}
        centered
        className="col-md-12"
        size="xl"
        onHide={handleCancelModal}
      >
        <Modal.Body>
          <FormulaForm
            parameters={deviceParameters.map((p) => ({
              name: `${p.name} (${p?.device?.name})`,
              value: `${p.name} (${p?.device?.name})`,
              _id: p._id,
            }))}
            mode={selectedFormula ? "edit" : "create"}
            data={selectedFormula}
            onFinish={onFinish}
            onCancel={handleCancelModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
