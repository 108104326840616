import React, { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FullScreen } from "react-full-screen";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { endpoint } from "../../api/endpoint.js";
import { api } from "../../api/request.js";
import { ThemeContext } from "../../App.js";
import { Dashboard4Board } from "../Settings/SelectedDashboardSettings/Dashboard4Board.jsx";

const DashboardFour = ({ handle }) => {
  const { _id: deviceId } = useSelector((state) => state?.user?.currentDevice || {});
  const { backgroundImage, dashboardType, cards } = useSelector((state) => state?.dashboard["4"]);

  useEffect(() => {
    document.title = "SEDA - Dashboard";
  }, []);

  const updateDashboard = async () => {
    await api.put(endpoint.update_dashboard_settings, {
      dashboardType,
      cards,
    });
  };

  useEffect(() => {
    return () => {
      if (cards?.length > 0) {
        updateDashboard();
      }
    };
  }, [cards]);

  const isDashboardInit = backgroundImage || cards.length;
  return (
    <div className="dashboard">
      <div className="container-fluid ">
        <FullScreen handle={handle}>
          {isDashboardInit ? (
            <Dashboard4Board loadData={true} />
          ) : (
            <div className="alert alert-warning text-center">
              Dashboard does not initiated yet. Set dashbaord in
              <Link to="/settings/dashboard-settings">dashboard setting</Link> page
            </div>
          )}
        </FullScreen>
      </div>
    </div>
  );
};

export default DashboardFour;
