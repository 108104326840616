import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import { ThemeContext } from "../../App.js";

const AreaChart = ({ name, title, data, viewType, baseload, dateRange, xAxisName }) => {
  console.log({ name, title, data, viewType, baseload, dateRange, xAxisName });
  let { isDark } = useContext(ThemeContext);
  const [chartInfo, setChartInfo] = useState({
    series: [
      {
        name: name,
        data: data,
      },
    ],
    options: getOptions({
      title,
      xAxisName,
      isDark,
      viewType,
      dateRange,
      baseload,
    }),
  });

  useEffect(() => {
    if (viewType === "custom" && !dateRange?.length) return;

    let newOptions = getOptions({
      title,
      isDark,
      viewType,
      dateRange,
      baseload,
    });
    setChartInfo({
      ...chartInfo,
      options: newOptions,
      series: [
        {
          name: name,
          data,
        },
      ],
    });
  }, [data, isDark, viewType, dateRange]);
  // console.log(chartInfo?.options?.xaxis);
  return (
    <div>
      <Chart options={chartInfo.options} series={chartInfo.series} type="area" height={350} />
      <p
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          textAlign: "center",
          margin: "-10px 0 0",
        }}
      >
        {xAxisName}
      </p>
    </div>
  );
};

export default AreaChart;

const getOptions = ({ title, isDark, viewType, baseload, dateRange }) => {
  let start = new Date();
  let end = new Date();

  if (viewType === "monthly") {
    start.setMonth(start.getMonth() - 12); //monthly
  } else if (viewType === "weekly") {
    start = new Date(start.getTime() - 7 * 24 * 60 * 60 * 1000); // decrease 7 days weekly
  } else if (viewType === "today") {
    // end = new Date(start.getTime() + 86400000);
  } else if (dateRange?.length && viewType === "custom") {
    start = new Date(dateRange[0].format("YYYY-MM-DD"));
    end = new Date(dateRange[1].format("YYYY-MM-DD"));
  }
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);

  return {
    chart: {
      id: "area-datetime",
      type: "area",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
        autoScaleXaxis: true,
      },
    },
    annotations: {
      yaxis: [
        {
          y: baseload || 30000,
          borderColor: "red",
          label: {
            show: true,
            text: "Baseload",
            style: {
              color: "#fff",
              background: "#00ab41",
            },
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      min: start.getTime(),
      max: end.getTime(),
      tickAmount: 1,
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      title: {
        text: "Power (KW)",
        style: {
          fontSize: "14px",
          // color: "#000",
        },
      },
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    title: {
      text: title,
      align: "center",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    theme: isDark ? { mode: "dark" } : { mode: "light" },

    stroke: { width: 2 },
  };
};
