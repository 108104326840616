import React, { useContext, useState } from "react";
import DataTable from "react-data-table-component";
import { FiTrash, FiEdit } from "react-icons/fi";
import EditParameterModal from "../../../components/Modals/EditParameterModal";
import Swal from "sweetalert2";
import { ThemeContext } from "../../../App.js";
import { notification, Popconfirm } from "antd";
import { api, endpoint } from "../../../api";

const ParametersTable = ({
  data,
  getParameters,
  setPage,
  setLimit,
  total,
  limit,
  listLoading,
}) => {
  let { isDark } = useContext(ThemeContext);

  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [parameterToEdit, setParameterToEdit] = useState();
  const columns = [
    {
      name: "No",
      cell: ({ sl }) => sl,
      selector: (row) => console.log(row),
      width: "60px",
    },
    {
      name: "System Parameter Name",
      selector: (row) => row?.name,
      grow: 2,
    },
    {
      name: "System Parameter Type",
      selector: (row) => row?.type,
      grow: 2,
    },
    {
      name: "Unit / Value",
      selector: (row) => row?.value,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <button
            className="btn btn-info me-1"
            onClick={() => editParameter(row)}
          >
            <FiEdit />
          </button>
          <Popconfirm
            title="Are you sure?"
            okButtonProps={{
              className: "btn btn-danger",
              loading: isLoading,
            }}
            onConfirm={() => {
              onDeleteParameter(row._id);
            }}
          >
            <button className="btn btn-danger">
              <FiTrash />
            </button>
          </Popconfirm>
        </div>
      ),
      grow: 2,
      center: "yes",
    },
  ];
  const editParameter = (data) => {
    setParameterToEdit(data);
    setModalShow(true);
  };
  const onDeleteParameter = async (parameterId) => {
    setIsLoading(true);
    try {
      const { message } = await api.delete(
        `${endpoint.delete_system_parameter}/${parameterId}`
      );
      notification.success({ message, placement: "bottomLeft" });
      getParameters();
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };

  return (
    <div>
      <h4 className="mb-2">List of All System Parameters</h4>
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationServer
        striped={!isDark}
        noDataComponent={listLoading ? "Loading" : "No record found."}
        theme={isDark ? "dark" : "light "}
        paginationTotalRows={total}
        paginationPerPage={limit}
        onChangePage={(data) => {
          setPage(data);
        }}
        onChangeRowsPerPage={(data) => {
          setLimit(data);
          setPage(1);
        }}
        paginationRowsPerPageOptions={[10, 20, 50]}
      />
      <EditParameterModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        parameterToEdit={parameterToEdit}
        setModalShow={setModalShow}
        getParameters={getParameters}
      />
    </div>
  );
};

export default ParametersTable;
