import axios from "axios";
import moment from "moment/moment";
import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { ThemeContext } from "../../App.js";
import { api, endpoint } from "../../api";
import { Spin } from "antd";

const DeviceData = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [deviceDetails, setDeviceDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [readings, setReading] = useState([]);
  const [tableColumn, setTableColmn] = useState([]);

  const Params = useParams();
  const deviceId = Params.deviceId;
  let { isDark } = useContext(ThemeContext);

  const getDevice = async () => {
    try {
      const response = await axios.get(`/device/` + deviceId, {
        withCredentials: true,
      });
      if (response) {
        setDeviceDetails(response.data?.result);
      }
    } catch (error) {}
  };

  const generateTableHeader = (deviceParameters) => {
    if (tableColumn.length === 0) {
      setTableColmn([
        {
          name: "No.",
          width: "60px",
          selector: ({ sl }) => sl,
          center: true,
        },
        {
          name: "Time",
          selector: (row) => moment(row._id).format("DD/MM/YYYY hh:mm:ss a"),
          width: "200px",
          center: true,
        },
        ...deviceParameters.map((name) => {
          return {
            name,
            selector: (row) => (row?.[name] ? Number(row?.[name]).toFixed(2) : "--"),
            center: true,
          };
        }),
      ]);
    }
  };

  // getting the table data
  const getDeviceData = async () => {
    setIsLoading(true);
    try {
      let { results, count, deviceParameters } = await api.get(endpoint.get_devices_data, {
        params: { deviceId, limit, page, sortBy: "date", orderBy: "DESC" },
      });
      results = results.map((item, idx) => {
        item.sl = (page - 1) * limit + idx + 1;
        return item;
      });

      generateTableHeader(deviceParameters);

      setReading(results);
      setTotal(count || 0);
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    getDevice();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDeviceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  return (
    <div className="card p-3 mb-3">
      <div className="row">
        <div className="col-md-6">
          <h3>Device Data Received</h3>
          <p style={{ fontSize: "16px", fontWeight: "500" }}>Device name: {deviceDetails?.name || "--"}</p>
        </div>
        <div className="col-md-6">
          <div className="d-flex justify-content-end">
            <button onClick={() => getDeviceData()} to="#" className="btn btn-success me-1">
              Refresh
            </button>
            <Link to="/devices" className="btn btn-secondary">
              Back
            </Link>
          </div>
        </div>
      </div>
      <Spin spinning={isLoading}>
        <DataTable
          columns={tableColumn}
          data={readings}
          noDataComponent={isLoading ? "Loading" : "No record found."}
          pagination
          paginationServer
          striped={!isDark}
          theme={isDark ? "dark" : "light "}
          paginationTotalRows={total}
          paginationPerPage={limit}
          onChangePage={(data) => {
            setPage(data);
          }}
          onChangeRowsPerPage={(data) => {
            setLimit(data);
            setPage(1);
          }}
          paginationRowsPerPageOptions={[10, 20, 50]}
        />
      </Spin>
    </div>
  );
};

export default DeviceData;
