import React from "react";

export const CounterBox = ({ title, unit, value }) => {
  return (
    <div className="consumption text-center">
      <div className="card text-center mb-2 p-2">
        <h4 className="text-success">{title}</h4>
        <h2>{(value || 0).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </h2>
        <p>{unit || "kWh"} </p>
      </div>
    </div>
  );
};
