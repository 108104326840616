import React from "react";

export const SingleState = ({ title, value }) => (
  <div className="row">
    <div className="col-6"> {title}: </div>
    <div className="col-6 d-flex justify-content-end">
      {(value || 0).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    </div>
  </div>
);
