import moment from "moment";

export const last24Hour = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];
export const getLast12MonthName = () => {
  let monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let months = [];
  let d = new Date();
  d.setDate(1);
  for (let i = 0; i < 12; i++) {
    months.unshift(
      monthName[d.getMonth()] + " " + d.getFullYear().toString().substring(2, 4)
    );
    d.setMonth(d.getMonth() - 1);
  }
  return months;
};

export const getLast7DaysName = () => {
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  let goBackDays = 7;

  let today = new Date();
  let daysSorted = [];

  for (let i = 0; i < goBackDays; i++) {
    let newDate = new Date(today.setDate(today.getDate() - 1));
    // console.log({ ll: newDate.getDay() });
    daysSorted.push(days[newDate.getDay()]);
  }
  return daysSorted.reverse();
};

export const getDatesInRange = (startDate, endDate) => {
  const date = new Date(startDate);
  const dates = [];
  while (date <= endDate) {
    dates.push(moment(new Date(date)).format("DD MMM,YY"));
    date.setDate(date.getDate() + 1);
  }
  return dates;
};
