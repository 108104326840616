import { notification } from "antd";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setCurrentDevice, setCurrentSite, setIsLogged, setSiteDetails, setUserDetails } from "../../redux/userSlice";

const VerifyEmail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  let data = 0;
  const verify = async () => {
    try {
      await axios.post(`/user/verify-email`, {
        userId: params.get("userId"),
        otp: params.get("otp"),
      });
      notification.success({ message: "Verification successfull", placement: "bottomRight" });
    } catch (error) {
      notification.error({ message: error?.response?.data?.message, placement: "bottomRight" });
    }
    navigate("/");
  };

  const logout = async () => {
    try {
      await axios.get(`/user/logout`, {
        withCredentials: true,
      });
      dispatch(setIsLogged(false));
      dispatch(setUserDetails({}));
      dispatch(setCurrentSite({}));
      dispatch(setSiteDetails({}));
      dispatch(setCurrentDevice({}));
      window.localStorage.clear();
    } catch (error) {}
    if (data === 0) verify();
    data = 1;
  };

  const startVerification = async () => {
    await logout();
    await verify();
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <div className="" style={{ display: "flex", height: "100vh", alignItems: "center", justifyContent: "center" }}>
      <h1>Verifying...</h1>
    </div>
  );
};

export default VerifyEmail;
