import { Spin } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BiEdit } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { api, endpoint } from "../../api";

const DashboardList = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const getDashboardList = async () => {
    if (!isLoading) {
      setIsLoading(true)
      const response = await api.get(endpoint.get_dashboard_multi_list, {
        params: {
          limit: 10,
          page: 1,
        },
      });
      setData(response.results)
      setTotal(response.count)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getDashboardList()
  }, [page, limit]);

  const columns = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      width: "60px",
    },
    {
      name: "Dashboard Name",
      cell: (row) => row.name,
    },
    {
      name: "Date Created",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      center: true,
    },
    {
      name: "Action",
      width: "220px",
      center: true,
      cell: (row) => (
        <div>
          {/* <Link to={`/settings/dashboard-settings/dashboardList/${row._id}`}>
            <Button variant="info" className="me-2">
              <AiOutlineEye className="text-white" />
            </Button>
          </Link> */}
          {/* EditDashboard */}

          <Link to={`/settings/dashboard-settings/dashboardEdit/${row.dashboardType}`}>
            <Button className="me-2">
              <BiEdit className="text-white" />
            </Button>
          </Link>
          <Button className="me-2" variant='danger' onClick={() => deleteHandler(row.dashboardType)}>
            <MdDeleteOutline className="text-white" />
          </Button>
        </div>
      ),
    },
  ];

  // handler
  const deleteHandler = type => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't to delete it!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        console.log({ type })
        if (result.isConfirmed) {
          const response = await axios.delete(`${endpoint.remove_dashboard}/${type}`, { withCredentials: true });
          if (response.status === 200) {
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
              timer: 1500,
              button: false,
            })
            getDashboardList()
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <div>
      <div className="card p-3 mb-3">
        <h3>Dashboard Settings</h3>
        <h5>Dashboard List</h5>

        <DataTable
          columns={columns}
          data={data}
          pagination
          paginationServer
          progressPending={isLoading}
          progressComponent={<Spin />}
          noDataComponent={isLoading ? "Loading" : "No record found."}
          className="mt-3"
          paginationTotalRows={total}
          paginationPerPage={limit}
          onChangePage={(data) => {
            setPage(data);
          }}
          onChangeRowsPerPage={(data) => {
            setLimit(data);
            setPage(1);
          }}
          paginationRowsPerPageOptions={[10, 20, 50]}
        />


      </div>

    </div>
  );
};

export default DashboardList;
