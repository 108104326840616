import React from "react";
import { SingleState } from "./SingleState";

export const OverallStats = ({ max, min, avg }) => {
  return (
    <div className="minmax bg-success bg-opacity-50 mt-2">
      <SingleState title="Min" value={min} />
      <SingleState title="Max" value={max} />
      <SingleState title="Average" value={avg} />
    </div>
  );
};
