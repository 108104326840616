import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="py-3 text-center">
              &copy; {new Date().getFullYear()} SEDA Malaysia. All Rights Reserved - Version 1.0.1 | 3.2024
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
