/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../api";
import styles from "./admin.module.css";

const SetupAdmin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ email: "", password: "", cpassword: "" });
  const onChange = (e) => setState({ ...state, [e.target.name]: e.target.value });

  useEffect(() => {
    axios.get("/user/setup").then((data) => {
      if (data.status !== 200) {
        navigate("/");
      }
    });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    if (state.password !== state.cpassword) {
      return alert("Password and confirm password did not match!");
    }
    setLoading(true);
    api
      .post("/user/setup", state)
      .then((data) => {
        alert(data.message);
        setLoading(false);
        if (data.success) {
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
      })
      .catch(() => setLoading(false));
  }

  return (
    <>
      <main className={styles.main}>
        <div className={styles.box}>
          <h1 style={{ textAlign: "center" }}>Welcome to SEDA API</h1>
          <h4 style={{ textAlign: "center" }}>Setup Super Admin</h4>

          <form action="/admin" onSubmit={handleSubmit}>
            <div style={{ marginBottom: "20px" }}>
              <label style={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>Email</label>
              <input type="email" className={styles.input} required name="email" onChange={onChange} />
            </div>

            <div style={{ marginBottom: "20px" }}>
              <label style={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>Password</label>
              <input type="password" className={styles.input} required name="password" onChange={onChange} />
            </div>

            <div style={{ marginBottom: "20px" }}>
              <label style={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>Confirm Password</label>
              <input type="password" className={styles.input} required onChange={onChange} name="cpassword" />
            </div>

            <button className={styles.btn}>{loading ? "Please wait..." : "Submit"}</button>
          </form>
        </div>
      </main>
    </>
  );
};

export default SetupAdmin;
