import { Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { FormulaForm } from "./FormulaForm";
import FormulasTable from "./FormulasTable";

const ManageFormulas = () => {
  const [parameters, setParameters] = useState([]);
  const [formulas, setFormulas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedFormula, setSelectedFormula] = useState();

  const getParameters = async () => {
    const response = await axios.get(`/system-parameter`, { withCredentials: true });
    if (response) {
      setParameters(response.data.results);
    }
  };

  const getFormulas = async () => {
    const response = await axios.get(`/formula`, {
      withCredentials: true,
      params: {
        type: "system",
      },
    });
    if (response) {
      setFormulas(response.data);
    }

    setLoading(false);
  };

  const onFinish = () => {
    handleCancelModal();
    getFormulas();
  };

  useEffect(() => {
    getParameters();
    getFormulas();
  }, []);

  const deleteFormula = async (formulaId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete formula?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/formula/` + formulaId, { withCredentials: true }).then((res) => {
          getFormulas();
          Swal.fire({
            title: "Done!",
            text: "Formula Deleted Successfully",
            icon: "success",
            timer: 2000,
            button: false,
          });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const handleEdit = (row) => {
    setSelectedFormula(row);
    setShowModal(true);
  };

  const handleCancelModal = () => {
    setShowModal(false);
    setSelectedFormula();
  };

  return (
    <div className="card p-3 mb-3">
      <div className="d-flex justify-content-between mb-4">
        <h3>Manage Formulas</h3>
        <button className="btn btn-success me-2" onClick={() => setShowModal(true)}>
          Create Formula
        </button>
      </div>
      <Modal
        show={showModal}
        style={{ width: "100%" }}
        centered
        className="col-md-12"
        size="xl"
        onHide={handleCancelModal}
      >
        <Modal.Body>
          <FormulaForm
            parameters={parameters}
            formulas={formulas}
            mode={selectedFormula ? "edit" : "create"}
            data={selectedFormula}
            onFinish={onFinish}
            onCancel={handleCancelModal}
          />
        </Modal.Body>
      </Modal>
      <Spin spinning={loading}>
        <div className="row">
          <FormulasTable
            formulas={formulas}
            getFormulas={getFormulas}
            onDeleteRow={(row) => deleteFormula(row._id)}
            onEditRow={handleEdit}
          />
        </div>
      </Spin>
    </div>
  );
};

export default ManageFormulas;
