import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { BsFullscreen, BsZoomIn, BsZoomOut } from "react-icons/bs";
import DashboardCard from "../Settings/SelectedDashboardSettings/DashboardCard";

export const DashboardMultiBoard = ({
  setCardModal,
  editable = false,
  loadData = false,
  backgroundImage,
  setBackgroundImage,
  cards,
  setCards,
}) => {
  const dragRef = useRef();

  const [imageWidth, setImageWidth] = useState();
  const [imageHeight, setImageHeight] = useState();
  const [isMoveable, setIsMoveable] = useState(false);

  /*    file upload
  
          if (dashboardDetails.backgroundImageFile) {
          const formData = new FormData();
          formData.append("file", dashboardDetails.backgroundImageFile);
          backgroundImage = await api.post("/file/upload", formData);
        }
  */

  const removeBackground = () => {
    setBackgroundImage(null);
  };

  const updateDashboardPosition = (id, { position }) => {
    const updated = cards.map((item) => {
      if (item._id === id) {
        item.position = position;
      }
      return item;
    });
    setCards(updated);
  };

  return (
    <div className="position-relative" style={{ background: "red" }}>
      <TransformWrapper
        wheel={{ disabled: true }}
        initialScale={1}
        centerZoomedOut
        centerOnInit
        minScale={0.5}
        maxScale={3}
        disabled={isMoveable}
        initialPositionX={100}
      >
        {({ zoomIn, zoomOut, resetTransform, centerView }) => {
          return (
            <>
              <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
                <div className="notSelect position-relative" style={{ minHeight: "200px" }}>
                  {cards?.map((item) => (
                    <DashboardCard
                      editable={editable}
                      key={item._id}
                      dragRef={dragRef}
                      item={item}
                      updatePosition={updateDashboardPosition}
                      deleteItem={(id) => {
                        const remain = cards.filter((entry) => entry._id !== id);
                        setCards(remain);
                      }}
                      editItem={(item) => setCardModal(item)}
                      loadData={loadData}
                      dragStart={() => setIsMoveable(true)}
                      dragEnd={() => setIsMoveable(false)}
                    />
                  ))}

                  <div>
                    {editable && Boolean(backgroundImage) ? (
                      <span
                        onClick={removeBackground}
                        className="position-absolute translate-middle badge border border-light rounded-circle bg-danger p-2"
                        style={{ zIndex: 9, top: 25, right: 0 }}
                      >
                        X <span className="visually-hidden">unread messages</span>
                      </span>
                    ) : (
                      ""
                    )}
                    {Boolean(backgroundImage) && (
                      <img
                        src={backgroundImage}
                        alt="Dashboard BG"
                        className="notSelect"
                        style={{
                          // width: imageWidth ? imageWidth : "100%",
                          width: '100vw',
                          height: imageHeight,
                        }}
                        onLoad={(e) => {
                          centerView();
                          setImageWidth(e.currentTarget.naturalWidth);
                          setImageHeight(e.currentTarget.naturalHeight);
                        }}
                      />
                    )}
                  </div>
                </div>
              </TransformComponent>

              <div
                style={{
                  position: "absolute",
                  left: 5,
                  bottom: 0,
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                }}
              >
                <button
                  onClick={() => {
                    resetTransform();
                  }}
                  className="px-2 btn btn-dark"
                  type="button"
                >
                  <BsFullscreen />
                </button>
                <button
                  onClick={() => {
                    zoomIn();
                  }}
                  className="px-2 btn btn-dark"
                  type="button"
                >
                  <BsZoomIn />
                </button>
                <button
                  onClick={() => {
                    zoomOut();
                  }}
                  className="px-2 btn btn-dark"
                  type="button"
                >
                  <BsZoomOut />
                </button>
              </div>
            </>
          );
        }}
      </TransformWrapper>
    </div>
  );
};
