import React from "react";
import { AssignDashboard } from "./AssignDashboard";
import { GeneralSettings } from "./GeneralSettings";
import { SectionHeader } from "./SectionHeader";
import "./SelectedDashboardSettings.css";

const SelectedDashboardSettings = ({ handleChangeTemplate, dashboardType = 1, dashboardDetails }) => {
  // const { data, loading } = useSystemParameters();
  // const graphTypes = useGraphType(dashboardType);

  // const handleChangeGraph = ({ type, parameter, _index }) => {
  //   const graphs = Array.from(dashboardDetails.graphs || []);

  //   let index = graphs.findIndex((a) => a.type === type);

  //   if (index >= 0) {
  //     graphs[index] = { type, parameter };
  //   } else {
  //     graphs.push({ type, parameter });
  //   }
  //   handleChangeTemplate({
  //     name: "graphs",
  //     value: graphs,
  //   });
  // };

  return (
    <div className="selectedDashboardSettings border-success">
      <h3 className="fw-bold mb-4">{dashboardDetails?.name || "Dashboard 1"}</h3>
      <SectionHeader text="General Settings" small={false} />
      <GeneralSettings dashboardDetails={dashboardDetails} handleChangeTemplate={handleChangeTemplate} />

      {dashboardDetails?.userType === "all" && (
        <>
          <SectionHeader text="Assign Dashboard" showUnderline={false} className="mt-3 mb-3" />
          <AssignDashboard dashboardType={dashboardType} dashboard={dashboardDetails?._id} />
        </>
      )}
      <div className="mt-4">
        {/* <SectionHeader text="Template Settings" small={false} className="mb-4" /> */}
{/* 
        {dashboardType === 1 && (
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="to1" className="form-label">
                Global Baseload
              </label>
              <input
                type="number"
                placeholder="Baseload For Graph 1"
                value={dashboardDetails?.baseLoad}
                onChange={(e) => handleChangeTemplate({ name: "baseLoad", value: e.target.value })}
                className="form-control"
              />
            </div>
          </div>
        )} */}

        {/* <div className="row mt-5">
          <div className="col-md-12">
            <SectionHeader text="Graph Settings" />
          </div>
          {graphTypes?.map(({ name, value }) => {
            const graphs = Array.from(dashboardDetails.graphs || []);
            let index = graphs.findIndex((a) => a.type === value);

            const graph = graphs[index];
            return (
              <div
                className="col-md-3"
                key={`graphType-${value}-${dashboardType}`}
                id={`graphType-${value}-${dashboardType}`}
              >
                <label htmlFor="to1" className="form-label">
                  {name}
                </label>
                <select
                  name="graphType"
                  id={`graphType-${value}-${dashboardType}`}
                  className="form-select"
                  value={graph?.parameter || ""}
                  onChange={(e) => {
                    handleChangeGraph({ type: value, parameter: e.target.value, index });
                  }}
                  required
                >
                  {loading ? <option value="">Loading...</option> : <option value=""> Select System Parameter</option>}
                  {data?.results?.length > 0 &&
                    data?.results.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name} ({item.value})
                      </option>
                    ))}
                </select>
              </div>
            );
          })}
        </div> */}
      </div>
    </div>
  );
};

export default SelectedDashboardSettings;
