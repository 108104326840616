import React from "react";
import UserSidebar from "./components/UserSidebar";

import { Outlet } from "react-router-dom";

const Users = () => {
  return (
    <div className="users">
      <div className="container-fluid">
        <div className="row my-5 vh60">
          <div className="col-md-2">
            <UserSidebar />
          </div>

          <div className="col-md-10">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
