import { notification, Popconfirm, Spin } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { AiOutlineEye } from "react-icons/ai";
import { FiTrash, FiUserCheck, FiUserX } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { api, endpoint } from "../../../api";
import { ThemeContext } from "../../../App";
import { GenericSelect } from "../../../components/Shared/GenericSelect";

export const AssignDashboard = ({ dashboardType, dashboard }) => {
  let { isDark } = useContext(ThemeContext);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [singleUser, setSingleUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [siteUserList, setSiteUserList] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const siteList = useSelector((store) => store?.user?.userSites || []);

  const handleInput = ({ name, value }) => {
    if (name === "site" || name === "userType") {
      setSingleUser({ ...singleUser, [name]: value, siteuser: "" });
    } else setSingleUser({ ...singleUser, [name]: value });
  };
  const getUsers = async () => {
    try {
      let { results, count } = await api.get(endpoint.all_users, {
        params: {
          limit: 10000,
          page: 1,
          sortBy: "createdAt",
          orderBy: "DESC",
          role: singleUser.userType,
          site: singleUser.site,
        },
      });
      // setSingleUser({ ...singleUser, siteUser: "" });
      setSiteUserList(results);
    } catch (error) {}
  };

  useEffect(() => {
    if (singleUser?.userType && singleUser?.site) {
      getUsers();
    }
  }, [singleUser]);

  const getAssinees = async () => {
    setIsLoading(true);
    try {
      let { results, count } = await api.get(endpoint.get_dashboard_assinees, {
        params: {
          limit: 10000,
          page: 1,
          sortBy: "createdAt",
          orderBy: "DESC",
          dashboardType,
        },
      });
      results = results.map((item, idx) => {
        item.sl = (page - 1) * limit + idx + 1;
        return item;
      });
      setAssignedUsers(results);
      setTotal(count);
    } catch (error) {}
    setIsLoading(false);
  };
  useEffect(() => {
    getAssinees();
  }, [limit, page]);

  const onAssignDashboard = async () => {
    try {
      const { message } = await api.post(endpoint.assinee_dashboard, {
        user: singleUser.siteUser,
        site: singleUser.site,
        dashboardType,
        dashboard,
      });
      notification.success({ message, placement: "bottomLeft" });
      getAssinees();
      setSingleUser({});
    } catch (error) {
      notification.warning({
        message: error?.response?.data?.message || "Something went wrong.",
        placement: "bottomLeft",
      });
    }
  };
  const onUpdateStaus = async (id) => {
    try {
      const { message } = await api.put(endpoint.assinee_status_update, {
        assigneeId: id,
      });
      notification.success({ message, placement: "bottomLeft" });
      getAssinees();
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
  };
  const deleteAssinee = async (id) => {
    try {
      const { message } = await api.delete(endpoint.delete_dashboard_assinee, {
        data: { assigneeId: id },
      });
      notification.success({ message, placement: "bottomLeft" });
      getAssinees();
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
  };

  const columns = [
    {
      name: "No.",
      selector: ({ sl }) => sl,
      width: "60px",
    },
    {
      name: "User Name",
      cell: (row) => <p className="m-0">{row?.user?.name || "--"}</p>,
      // width: "100%",
    },
    {
      name: "Email",
      cell: (row) => row?.user?.email,
      // width: "150px",
    },
    {
      name: "User Type",
      cell: (row) => <span className="text-capitalize">{row?.user?.role || "--"}</span>,
      // width: "150px",
      center: true,
    },
    {
      name: "Site",
      cell: (row) => <div>{row?.site?.name || "--"}</div>,
      // width: "150px",
    },
    {
      name: "Date Assigned",
      selector: (row) => moment(row?.createdAt || new Date()).format("DD/MM/YYYY"),
      // width: "130px",
      center: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {row.isActive ? (
            <span className="badge text-bg-success">Active</span>
          ) : (
            <span className="badge text-bg-danger">Deactivated</span>
          )}
        </div>
      ),
      width: "100px",
      center: true,
    },
    {
      name: "Action",
      width: "200px",
      center: true,
      cell: (row) => {
        let link = "/installer/";
        if (row?.user?.role === "user") link = "/users/details/";
        else if (row?.user?.role === "public") link = "/public-user/";
        else if (row?.user?.role === "admin") link = "/admin/";
        return (
          <>
            <Link to={`/users/details/6404b36b2d16e9e3449fdfac?role=${row?.user?.role}`} className="btn btn-info me-1">
              <AiOutlineEye title="View Profile" />
            </Link>
            {row?.isActive ? (
              <button  title="Unassign User"  className="btn btn-warning  me-1" onClick={() => onUpdateStaus(row._id)}>
                <FiUserX/>
              </button>
            ) : (
              <button className="btn btn-success  me-1"  title="Active Account" onClick={() => onUpdateStaus(row._id)}>
                <FiUserCheck/>
              </button>
            )}

            <Popconfirm
              title="Are you sure?"
              okButtonProps={{
                className: "btn btn-danger",
                loading: isLoading,
              }}
              onConfirm={() => {
                deleteAssinee(row._id);
              }}
            >
              <button className="btn btn-danger">
                <FiTrash title="Delete User" />
              </button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col md={3}>
          <GenericSelect
            label="Site Location"
            name="site"
            value={singleUser?.site}
            options={siteList.map(({ _id, name }) => ({ value: _id, name }))}
            handleInput={handleInput}
            placeholder="Select site"
          />
        </Col>
        <Col md={3}>
          <GenericSelect
            label="User Type"
            name="userType"
            options={userRoleOptions}
            handleInput={handleInput}
            placeholder="Select role"
            value={singleUser?.userType}
          />
        </Col>
        <Col md={3}>
          <GenericSelect
            label="Site User"
            name="siteUser"
            options={siteUserList.map(({ _id, name }) => ({
              value: _id,
              name,
            }))}
            handleInput={handleInput}
            placeholder="Select user"
            value={singleUser?.siteUser}
          />
        </Col>
        <Col md={3}>
          <div className="mt-4 pt-2">
            <button type="submit" className="btn btn-success" onClick={onAssignDashboard}>
              Assign
            </button>
          </div>
        </Col>

        {/* table */}
        <Col md={12}>
          <hr className="mt-5" />
          <Spin spinning={isLoading}>
            <DataTable
              columns={columns}
              data={assignedUsers}
              pagination
              paginationServer
              striped={!isDark}
              noDataComponent={isLoading ? "Loading" : "No record found."}
              theme={isDark ? "dark" : "light "}
              className="mt-2"
              paginationTotalRows={total}
              paginationPerPage={limit}
              onChangePage={setPage}
              onChangeRowsPerPage={(data) => {
                setLimit(data);
                setPage(1);
              }}
              paginationRowsPerPageOptions={[10, 20, 50]}
            />
          </Spin>
        </Col>
      </Row>
    </>
  );
};

const userRoleOptions = [
  { value: "installer", name: "Installer" },
  { value: "admin", name: "Admin" },
  { value: "user", name: "User" },
  { value: "public", name: "Public" },
];
