import axios from "axios";
import React, { useMemo, useState } from "react";
import { FiTrash } from "react-icons/fi";
import Select from "react-select";
import { api, endpoint } from "../../api";

export const DeviceAndParameter = ({
  deviceNumber,
  device,
  parameters = [],
  onRemoveDevice,
  onChangeDevice,
  addedDevices,
  sites,
}) => {
  const [deviceParameters, setDeviceParameters] = useState();
  const [deviceList, setListDevices] = useState([]);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const [isLoadingParameters, setIsLoadingParameters] = useState(false);

  const onChangeSite = async (locationId) => {
    onChangeDevice({ idx: deviceNumber - 1, value: "", name: "device" });
    setIsLoadingDevices(true);
    try {
      const response = await axios.get(`/device`, {
        withCredentials: true,
        params: { site: locationId },
      });
      if (response) {
        setListDevices(response.data?.results);
      }
    } catch (error) {}
    setIsLoadingDevices(false);
  };

  const getDeviceParameters = async (deviceId) => {
    setIsLoadingParameters(true);
    try {
      setDeviceParameters([]);
      const { results } = await api.get(endpoint.get_device_parameter, {
        params: {
          deviceId,
          limit: 1000,
          page: 1,
          sortBy: "name",
          orderBy: "ASC",
          type: "formula",
        },
      });
      setDeviceParameters(results);
    } catch (error) {}

    setIsLoadingParameters(false);
  };

  const onChangeDev = (e) => {
    const deviceId = e.target.value;
    getDeviceParameters(deviceId);
    onChangeDevice({ idx: deviceNumber - 1, value: deviceId, name: "device" });
    onChangeDevice({ idx: deviceNumber - 1, value: [], name: "parameters" });
  };
  const onChangeParm = (value, name) => {
    onChangeDevice({
      idx: deviceNumber - 1,
      value: value.map((v) => v.label),
      name: "parameters",
    });
  };

  const options = useMemo(() => {
    if (deviceParameters) {
      return deviceParameters.map((item) => {
        return { value: item.name, label: item.name };
      });
    } else {
      return [];
    }
  }, [deviceParameters]);

  const parameterValues = useMemo(() => {
    return parameters.map((item) => {
      return { value: item, label: item };
    });
  }, [parameters]);

  return (
    <div className="row">
      <div className="col-md-3 mt-2">
        <select
          className="form-select"
          name="siteLocations"
          onChange={(e) => onChangeSite(e.target.value)}
          aria-label="Select an admin"
          defaultValue={""}
        >
          <option disabled label="Select Site" value={""}></option>
          {sites?.length &&
            sites.map((item, index) => (
              <option value={item._id} key={index}>
                {item.name}
              </option>
            ))}
        </select>
      </div>
      <div className="col-md-3 mt-2">
        <select className="form-select mb-2" name="device" value={device || ""} onChange={onChangeDev}>
          {isLoadingDevices ? (
            <option value="">Loading devices...</option>
          ) : (
            <>
              <option value={""}>Select Device {deviceNumber}</option>
              {deviceList?.length > 0 &&
                deviceList.map((device) => {
                  return (
                    <option value={device._id} key={device?._id}>
                      {device.name}
                    </option>
                  );
                })}
            </>
          )}
        </select>
      </div>
      <div className="col-md-3 mt-2">
        <Select
          isMulti
          // defaultInputValue={parameters.map((p) => ({ label: p, value: p }))}
          onChange={(value) => onChangeParm(value, "parameters")}
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          isSearchable={true}
          placeholder="Select Parameters"
          value={parameterValues}
          isLoading={isLoadingParameters}
        />
      </div>
      <div className="col-md-3 mt-2">
        <button
          className="btn btn-danger"
          onClick={(e) => {
            e.preventDefault();
            onRemoveDevice(deviceNumber);
          }}
        >
          <FiTrash title="Delete Device" />
        </button>
      </div>
    </div>
  );
};
