import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Swal from "sweetalert2";
import { api, endpoint } from "../../api";

const ChangePassword = () => {
  const [ErrorMessageNotMatch, setErrorMessageNotMatch] = useState();
  const [isActive, setIsActive] = useState(false);
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const toggleCurrentPassword = () => {
    setCurrentPasswordShown(!currentPasswordShown);
  };
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const toggleNewPasswordShown = () => {
    setNewPasswordShown(!newPasswordShown);
  };
  const [repeatPasswordShown, setRepeatPasswordShown] = useState(false);
  const toggleRepeatPasswordShown = () => {
    setRepeatPasswordShown(!repeatPasswordShown);
  };
  const [userData, setUserData] = useState({
    currentPassword: "",
    password: "",
    repeatPassword: "",
  });
  const { currentPassword, password, repeatPassword } = userData;
  const onInputChange = (e) => {
    if (e.target.name === "repeatPassword") {
      setUserData({ ...userData, [e.target.name]: e.target.value });
      if (password === e.target.value) {
        setErrorMessageNotMatch();
        setIsActive(true);
      } else {
        setErrorMessageNotMatch("Both Passwords Are Not Matching");
      }
    } else {
      setUserData({ ...userData, [e.target.name]: e.target.value });
    }
  };
  useEffect(() => {
    document.title = "SEDA - Change Password";
  }, []);
  const onChangePage = async () => {
    try {
      const { message } = await api.put(endpoint.update_user_password, userData);
      Swal.fire({
        title: "Done!",
        text: message,
        icon: "success",
        timer: 2000,
        button: false,
      });
      setUserData({
        currentPassword: "",
        password: "",
        repeatPassword: "",
      });
    } catch (error) {
      Swal.fire({
        title: "Cancelled!",
        text: error?.response?.data?.message || "Something went wrong!",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to update password?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        onChangePage();
      }
    });
  };

  return (
    <div className="card p-3 mb-3">
      <h3>Change Password</h3>
      <div className="row mt-4">
        <div className="col-md-6">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="currentPassword" className="form-label">
                Current Password
              </label>
              <div className="input-group">
                <input
                  type={currentPasswordShown ? "text" : "password"}
                  name="currentPassword"
                  value={currentPassword}
                  minLength="6"
                  onChange={onInputChange}
                  className="form-control"
                  id="currentPassword"
                  placeholder="Enter your current password"
                  required
                />
                <span className="input-group-text" id="basic-addon1">
                  {currentPasswordShown ? (
                    <BsEyeSlash onClick={() => toggleCurrentPassword()} />
                  ) : (
                    <BsEye onClick={() => toggleCurrentPassword()} />
                  )}
                </span>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                New Password
              </label>
              <div className="input-group">
                <input
                  type={newPasswordShown ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={onInputChange}
                  minLength="6"
                  className="form-control"
                  id="password"
                  placeholder="Enter a new password"
                  required
                />
                <span className="input-group-text" id="basic-addon1">
                  {newPasswordShown ? (
                    <BsEyeSlash onClick={() => toggleNewPasswordShown()} />
                  ) : (
                    <BsEye onClick={() => toggleNewPasswordShown()} />
                  )}
                </span>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="repeatPassword" className="form-label">
                Retype New Password
              </label>
              <div className="input-group">
                <input
                  type={repeatPasswordShown ? "text" : "password"}
                  name="repeatPassword"
                  value={repeatPassword}
                  minLength="6"
                  onChange={onInputChange}
                  className="form-control"
                  id="repeatPassword"
                  placeholder="Retype the new password"
                  required
                />
                <span className="input-group-text" id="basic-addon1">
                  {repeatPasswordShown ? (
                    <BsEyeSlash onClick={() => toggleRepeatPasswordShown()} />
                  ) : (
                    <BsEye onClick={() => toggleRepeatPasswordShown()} />
                  )}
                </span>
              </div>
              {ErrorMessageNotMatch && (
                <div className="alert alert-danger" role="alert">
                  {ErrorMessageNotMatch}{" "}
                </div>
              )}
            </div>
            <div className="float-end">
              {isActive ? (
                <button type="submit" className="btn btn-success me-2">
                  Update
                </button>
              ) : (
                <button type="submit" className="btn btn-success me-2" disabled>
                  Update
                </button>
              )}
              <Link to="/" className="btn btn-secondary">
                Cancel
              </Link>
            </div>
          </form>
        </div>
        <div className="col-md-6"></div>
      </div>
    </div>
  );
};

export default ChangePassword;
