import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api, endpoint } from "../../api";
import DashboardForm from "./DashboardForm";

const EditDashboard = () => {
  const { type } = useParams();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getDashboard = async () => {
    if (!isLoading && type) {
      setIsLoading(true);
      try {
        const { result } = await api.get(endpoint.get_dashboard_setting, {
          params: { dashboardType: type },
        });
        setData(result);
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboard();
  }, [type]);

  return (
    <div>
      <DashboardForm mode="edit" data={data} />
    </div>
  );
};

export default EditDashboard;
