export const userRole = {
  superAdmin: "superAdmin",
  installer: "installer",
  admin: "admin",
  user: "user",
  public: "public",
};

export const GRAPH_TYPE = {
  POWER: "POWER",
  ENERGY_CONSUMPTION: "ENERGY_CONSUMPTION",
  BUILDING_ENERGY_INDEX: "BUILDING_ENERGY_INDEX",
  CO2_EMISSION: "CO2_EMISSION",
};
