import React from "react";

export const GenericInput = ({
  type,
  name,
  label,
  value,
  placeholder,
  handleInput,
  wrapperClassName,
  required
}) => {
  return (
    <div className={`w-100 ${wrapperClassName}`}>
      <label className="form-label">{label}</label>
      <input
        value={value || ''}
        type={type || "text"}
        className="form-control"
        onChange={(e) => handleInput({ name, value: e.target.value })}
        placeholder={placeholder}
        required={required || false}
      />
    </div>
  );
};
