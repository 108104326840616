import React, { useContext, useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { FullScreen } from "react-full-screen";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { api, endpoint } from "../../api";
import { Dashboard4Board } from "../Settings/SelectedDashboardSettings/Dashboard4Board";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import DashboardCard from "../Settings/SelectedDashboardSettings/DashboardCard";
import { BsFullscreen, BsZoomIn, BsZoomOut } from "react-icons/bs";

const DashboardFour = ({ handle, editable = false }) => {
  const dragRef = useRef();
  const dispatch = useDispatch();
  const { _id: deviceId } = useSelector((state) => state?.user?.currentDevice || {});
  const { currentDashboardtype } = useSelector((state) => state?.user);

  const [isLoading, setIsLoading] = useState(false);
  const [dashboard, setDashboard] = useState({});
  const [isMoveable, setIsMoveable] = useState(false);
  const [imageWidth, setImageWidth] = useState();
  const [imageHeight, setImageHeight] = useState();

  const { backgroundImage, dashboardType, cards } = dashboard;

  useEffect(() => {
    document.title = "SEDA - Dashboard";
  }, []);

  const getDashboard = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const { result } = await api.get(endpoint.get_dashboard_setting, {
          params: { dashboardType: currentDashboardtype },
        });
        console.log(result);
        setDashboard(result);
      } catch (error) {}
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboard();
    // eslint-disable-next-line
  }, [currentDashboardtype]);

  const updateDashboard = async () => {
    await api.put(endpoint.update_dashboard_settings, {
      dashboardType,
      cards,
    });
  };

  useEffect(() => {
    return () => {
      if (cards?.length > 0) {
        updateDashboard();
      }
    };
  }, [cards]);

  const isDashboardInit = backgroundImage || cards?.length;
  return (
    <div className="dashboard">
      <div className="container-fluid ">
        <FullScreen handle={handle}>
          {isDashboardInit ? (
            <div className="position-relative">
              <TransformWrapper
                wheel={{ disabled: true }}
                initialScale={1}
                centerZoomedOut
                centerOnInit
                minScale={0.5}
                maxScale={3}
                disabled={isMoveable}
                initialPositionX={100}
              >
                {({ zoomIn, zoomOut, resetTransform, centerView }) => {
                  return (
                    <>
                      <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
                        <div
                          className="notSelect position-relative"
                          style={{
                            backgroundColor: Boolean(backgroundImage) ? " transparent" : "#e3e2e7",
                            height: "auto",
                          }}
                        >
                          {cards?.length > 0 &&
                            cards?.map((item) => (
                              <DashboardCard
                                editable={editable}
                                loadData={true}
                                key={item._id}
                                dragRef={dragRef}
                                item={item}
                                updatePosition={(id, { position }) => {
                                  const updated = cards.map((item) => {
                                    if (item._id === id) {
                                      item.position = position;
                                    }
                                    return item;
                                  });

                                  setDashboard({ ...dashboard, cards: updated });
                                }}
                                dragStart={() => {
                                  setIsMoveable(true);
                                }}
                                dragEnd={() => {
                                  setIsMoveable(false);
                                }}
                              />
                            ))}
                          <div>
                            <img
                              src={backgroundImage}
                              alt="Dashboard BG"
                              className="notSelect"
                              style={{
                                // width: imageWidth,
                                width: "80vw",
                                height: imageHeight,
                              }}
                              onLoad={(e) => {
                                centerView();
                                setImageWidth(e.currentTarget.naturalWidth);
                                setImageHeight(e.currentTarget.naturalHeight);
                              }}
                            />
                          </div>
                        </div>
                      </TransformComponent>

                      <div
                        style={{
                          position: "absolute",
                          left: 5,
                          bottom: 0,
                          display: "flex",
                          flexDirection: "column",
                          gap: 5,
                        }}
                      >
                        <button
                          onClick={() => {
                            resetTransform();
                          }}
                          className="px-2 btn btn-dark"
                        >
                          <BsFullscreen />
                        </button>
                        <button
                          onClick={() => {
                            zoomIn();
                          }}
                          className="px-2 btn btn-dark"
                        >
                          <BsZoomIn />
                        </button>
                        <button
                          onClick={() => {
                            zoomOut();
                          }}
                          className="px-2 btn btn-dark"
                        >
                          <BsZoomOut />
                        </button>
                      </div>
                    </>
                  );
                }}
              </TransformWrapper>
            </div>
          ) : (
            <div className="alert alert-warning text-center">
              Dashboard does not initiated yet. Set dashbaord in{" "}
              <Link to="/settings/dashboard-settings">dashboard setting</Link> page
            </div>
          )}
        </FullScreen>
      </div>
    </div>
  );
};

export default DashboardFour;
