

import { Spin } from "antd";
import React, { useContext } from "react";
import { ThemeContext } from "../../App";
import CanvasJSReact from "../../assets/canvasjs.react";
import CanvasJS from "../../assets/canvasjs.min";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const SplineChart = ({ title, subtitle, data = [], isLoading = false, yAxis, xAxis, baseload = 0 }) => {
  const { isDark } = useContext(ThemeContext);
  CanvasJS.addColorSet("greenShades", ["#3f9ffb", "#3ffbe8", "#f36a08", "#3ffb42", "#fb3f6b"]);
  return (
    <Spin spinning={isLoading}>
      <CanvasJSChart
        options={{
          zoomEnabled: true,
          theme: isDark ? "dark1" : "light1",
          animationEnabled: false,
          title: {
            text: title,
          },
          subtitles: [
            {
              text: subtitle,
            },
          ],
          axisY: {
            includeZero: false,
            // prefix: "₹",
            ...yAxis,
          },
          legend: {
            cursor: "pointer",
            itemclick: function (e) {
              if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
              } else {
                e.dataSeries.visible = true;
              }
              e.chart.render();
            },
          },

          toolTip: {
            shared: true,
          },
          colorSet: "greenShades",
          data: [
            ...data.map((d) => ({
              type: "splineArea",
              name: d.name,
              xValueFormatString: "MMM YYYY, hh:mm TT",
              // yValueFormatString: "custom-format",
              showInLegend: true,
              dataPoints: d.data.map((t) => ({ x: new Date(t.x), y: t.y })),
            })),
          ],
        }}
      />
    </Spin>
  );
};

export default SplineChart;
