import { useEffect, useState } from "react";
import { api } from "../api";

export const useGraphSettings = ({ page = 1, limit = 10000 } = {}) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      api
        .get("/dashboard/graph-setting")
        .then((res) => {
          setData(res.results);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, []);

  return { data, loading, error };
};
