import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLogged: false,
    userDetails: {},
    userSites: [],
    currentDeviceList: [],
    currentSite: {},
    currentDevice: {},
    currentDashboardtype: 1,
  },
  reducers: {
    setIsLogged: (state, action) => {
      state.isLogged = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setSiteDetails: (state, action) => {
      state.userSites = action.payload;
    },
    setCurrentSite: (state, action) => {
      state.currentSite = action.payload;
    },
    setCurrentDevice: (state, action) => {
      state.currentDevice = action.payload;
    },
    setCurrentDashboard: (state, action) => {
      state.currentDashboardtype = action.payload;
    },
    setcurrentDeviceList: (state, action) => {
      state.currentDeviceList = action.payload;
    },
  },
});

export const {
  setIsLogged,
  setcurrentDeviceList,
  setUserDetails,
  setSiteDetails,
  setCurrentSite,
  setCurrentDevice,
  setCurrentDashboard,
} = userSlice.actions;

export default userSlice;
