import React, { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { api, endpoint } from "../../api";
import Dashboard4Modal from "../../components/Modals/Dashboard4Modal";
import { DashboardMultiBoard } from "./DashboardMultiBoard";
import { SectionHeader } from "../Settings/SelectedDashboardSettings/SectionHeader";
import { GeneralSettings } from "../Settings/SelectedDashboardSettings/GeneralSettings";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";

const DashboardForm = ({ mode, data }) => {
  const navigate = useNavigate();
  const [cardModal, setCardModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [backgroundImageFile, setBackgroundImageFile] = useState({});
  const [cards, setCards] = useState([]);
  const [dashboardDetails, setDashboardDetails] = useState({
    name: "",
    userType: "all",
    dashboardType: "",
  });

  useEffect(() => {
    if (data) {
      setBackgroundImage(data?.backgroundImage);
      setCards(data?.cards);
      setDashboardDetails({ ...data });
    }
  }, [data]);

  const handleFileUpload = (file) => {
    if (!file?.type?.startsWith("image/")) {
      return console.log("not a image");
    }
    setBackgroundImage(URL.createObjectURL(file));
    setBackgroundImageFile(file);
  };

  const DashboardMultiBoardProps = {
    editable: true,
    setCardModal,
    cards,
    setCards,
    backgroundImage,
    setBackgroundImage,
  };

  const handleChangeTemplate = ({ name, value }) => {
    setDashboardDetails({ ...dashboardDetails, [name]: value });
  };

  const create = async () => {
    try {
      if (!backgroundImage) {
        return alert("Please choose a background");
      }
      if (!isLoading) {
        setIsLoading(true);

        const formData = new FormData();
        formData.append("file", backgroundImageFile);
        let bgImage = await api.post("/file/upload", formData);

        const res = await api.put(endpoint.update_dashboard_settings, {
          dashboardType: dashboardDetails?.dashboardType || new Date().getTime(),
          name: dashboardDetails?.name,
          backgroundImage: bgImage || data?.backgroundImage,
          cards: cards.map((d) => ({ ...d, _id: undefined })),
          userType: dashboardDetails.userType,
        });
        setIsLoading(false);
        navigate("/settings/dashboard-settings/dashboardList");
        if (res) {
          Swal.fire({
            title: "Done!",
            text: mode === "edit" ? "Dashboard Updated Successfully." : "Dashboard Created Successfully.",
            icon: "success",
            timer: 1500,
            button: false,
          });
        }
      }
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const update = async () => {
    try {
      if (!backgroundImage) {
        return alert("Please choose a background");
      }
      if (!isLoading) {
        setIsLoading(true);
        let bgImage;
        if (dashboardDetails.backgroundImage !== backgroundImage) {
          const formData = new FormData();
          formData.append("file", backgroundImageFile);
          bgImage = await api.post("/file/upload", formData);
        }
        const res = await api.put(endpoint.update_dashboard_settings, {
          dashboardType: dashboardDetails?.dashboardType || new Date().getTime(),
          name: dashboardDetails?.name,
          backgroundImage: bgImage || data?.backgroundImage,
          cards: cards.map((d) => ({ ...d, _id: undefined })),
          userType: dashboardDetails.userType,
        });
        // setBackgroundImage("");
        setBackgroundImageFile({});
        setIsLoading(false);
        if (res) {
          Swal.fire({
            title: "Done!",
            text: mode === "edit" ? "Dashboard Updated Successfully." : "Dashboard Created Successfully.",
            icon: "success",
            timer: 1500,
            button: false,
          });
        }
      }
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mode === "edit") {
      update();
    } else {
      create();
    }
  };

  console.log(cardModal);

  return (
    <Spin spinning={isLoading}>
      <form onSubmit={handleSubmit}>
        <h3 className="fw-bold mb-4 text-capitalize">Create Dashboard</h3>
        <SectionHeader text="General Settings" small={false} />
        <GeneralSettings dashboardDetails={dashboardDetails} handleChangeTemplate={handleChangeTemplate} />

        <SectionHeader text="Template Settings" small={false} className="mb-4" />

        <div className="selectedDashboardSettings border-success mb-4">
          <div className="mb-4">
            <Button variant="success" type="button" onClick={() => setCardModal(true)}>
              Add Items
            </Button>

            <label className="btn btn-primary mx-3" htmlFor="fileUpload">
              Upload Background Image
              <input
                className="form-control"
                accept="image/*"
                type="file"
                id="fileUpload"
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e.target.files[0])}
              />
            </label>
          </div>

          <DashboardMultiBoard {...DashboardMultiBoardProps} />

          <Dashboard4Modal
            cardModal={cardModal}
            setCardModal={setCardModal}
            data={"cards"}
            onSave={(item, mode) => {
              console.log(item);
              if (mode === "edit") {
                const updated = cards.map((i) => {
                  if (i._id === item._id) {
                    Object.assign(i, item);
                  }
                  return i;
                });
                setCards(updated);
              } else {
                setCards([...cards, item]);
              }
            }}
          />
        </div>

        <div className="d-flex">
          <button className="btn btn-success ms-auto" type="submit">
            {mode === "edit" ? "Update Dashboard" : "Create Dashboard"}
          </button>
        </div>
      </form>
    </Spin>
  );
};

export default DashboardForm;
