import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import Draggable from "react-draggable";
import { MdEdit } from "react-icons/md";

const DashboardCard = ({
  item = {},
  dragRef,
  updatePosition,
  deleteItem,
  editItem,
  loadData = false,
  editable = false,
  dragStart,
  dragEnd,
}) => {
  const cardRef = useRef();
  const [value, setValue] = useState(0);
  const [unit, setUnit] = useState("");

  const handleDrag = (ui) => {
    console.log({ ui });
    if (updatePosition) {
      updatePosition(item._id, { position: { x: ui.x, y: ui.y } });
    }
    dragEnd();
  };

  const getSummery = () => {
    // if (loadData) {
    axios
      .get("/dashboard/summery-by-device", {
        withCredentials: true,
        params: {
          parameter: item.parameter,
          operation: item.operation,
          period: item.period,
        },
      })
      .then((res) => {
        setUnit(res.data.unit);
        if (res.data.result?.value) {
          setValue(parseFloat(res.data.result.value).toFixed(2));
        } else setValue(0);
      });
    // }
  };

  useEffect(() => {
    getSummery();
    const interval = setInterval(() => {
      getSummery();
    }, 60000);

    console.log("item", item);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.parameter, item.operation, item.period, loadData]);

  return (
    <>
      <Draggable
        onDrag={dragStart}
        onMouseDown={dragStart}
        ref={dragRef}
        axis="both"
        handle=".handle"
        defaultPosition={{ x: 0, y: 0 }}
        position={{ x: item.position?.x, y: item.position?.y }}
        scale={1}
        bounds="parent"
        onStop={(_e, ui) => handleDrag(ui)}
      >
        <div
          ref={cardRef}
          style={{
            minWidth: "300px",
            userSelect: "none",
            position: "absolute",
            zIndex: 999,
            backgroundColor: item.backgroundColor,
          }}
          className="handle"
        >
          <div style={{ width: "100%" }}>
            {editable && (
              <span
                onClick={() => deleteItem(item._id)}
                className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2 cursor-pointer"
                style={{ zIndex: 9 }}
              >
                X <span className="visually-hidden">unread messages</span>
              </span>
            )}
            {editable && (
              <span
                onClick={() => editItem(item)}
                className="position-absolute top-0 start-0 translate-middle badge border border-light rounded-circle bg-info p-2 cursor-pointer"
                style={{ zIndex: 9 }}
              >
                <MdEdit /> <span className="visually-hidden">unread messages</span>
              </span>
            )}
            <div
              className="d-flex justify-content-between p-3"
              style={{ color: item.textColor, fontSize: 16, gap: 10 }}
            >
              <div className="ellipsis">{item.title}</div>
              <div>{value ? `${value} (${unit})` : "0.00"}</div>
            </div>
            {editable && (
              <div
                className="d-flex justify-content-between"
                style={{ color: item.textColor, fontSize: 10, gap: 10, width: "100%", background: "#22222266" }}
              >
                {item.period && <span className="py-1 px-2">{item.period}</span>}{" "}
                {item.operation && <span className=" py-1 px-2">{item.operation}</span>}
              </div>
            )}
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default DashboardCard;
