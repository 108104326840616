import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FullScreen } from "react-full-screen";
import LineChart from "../../components/Charts/LineChart";
import { api, endpoint } from "../../api";
import BarChart from "../../components/Charts/BarChart";
import { useAxios } from "../../hooks/useAxios";
import DahsobardTwoBarChart from "../../components/Charts/DahsobardTwoBarChart";

const DashboardTwo = ({ handle }) => {
  let { currentDevice, currentSite } = useSelector((state) => state?.user || {});
  const [power, setPower] = useState([]);
  const [consumption, setConsumption] = useState([]);
  const [source, setSource] = useState([]);
  // const [sourceTotal, setSourceTotal] = useState(0);
  const [summary, setSummary] = useState({});

  const [powerTop10, setPowerTop10] = useState(true);
  const [energyTop10, setEnergyTop10] = useState(true);
  const [sourceTop10, setSourceTop10] = useState(true);

  useEffect(() => {
    document.title = "SEDA - Dashboard";
    getSummary();

    // eslint-disable-next-line
  }, [currentDevice, currentSite]);

  const params = {
    deviceId: currentDevice?._id,
    siteId: currentSite?._id,
    type: "monthly",
  };

  // power by month
  const getPower = (ownParams = {}) => {
    return api
      .get(endpoint.get_power_consumption_building, {
        params: {
          ...params,
          ...ownParams,
        },
      })
      .then((res) => setPower(res.results))
      .catch((err) => console.log(err));
  };

  // energy by month
  const getEnergyConsumption = (ownParams = {}) => {
    return api
      .get(endpoint.get_energy_consumption_building, {
        params: {
          ...params,
          ...ownParams,
        },
      })
      .then((res) => setConsumption(res.results))
      .catch((err) => console.log(err));
  };

  // building energy by month
  const getBuildingEnergySource = (ownParams = {}) => {
    api
      .get(endpoint.get_energy_source_percentage, {
        params: {
          ...params,
          ...ownParams,
        },
      })
      .then((res) => {
        setSource(res.results.map(item => ({ x: item.energySource, y: item.percentage }))
        );

        // setSource([
        //   {
        //     "energySource": "Solar Thermal",
        //     "percentage": 2.941176470588235
        //   },
        //   {
        //     "energySource": "Electrical Power",
        //     "percentage": 97.05882352941177
        //   }
        // ].map(item => ({ x: item.energySource, y: item.percentage })))
      })
      .catch((err) => console.log(err));
  };

  // building energy by month
  const getSummary = () => {
    return api
      .get(endpoint.dashboard_summary)
      .then((res) => setSummary(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPower({ top10: powerTop10 });
  }, [powerTop10]);

  useEffect(() => {
    getEnergyConsumption({ top10: energyTop10 });
  }, [energyTop10]);

  useEffect(() => {
    getBuildingEnergySource({ top10: sourceTop10 });
  }, [sourceTop10]);

  return (
    <div className="dashboard">
      <div className="container-fluid">
        <FullScreen handle={handle}>
          <div className="row  mt-4">
            <div className="col-md-4">
              <div className="card p-3 text-center">
                <h4>Total Power by Building, kW</h4>
                <h6>(Monthly)</h6>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i
                    className={`cursor-pointer ${powerTop10 ? "text-black" : "text-muted"}`}
                    onClick={() => setPowerTop10(true)}
                    title="Top 10"
                  >
                    Top 10 &uarr;
                  </i>
                  <i
                    className={`cursor-pointer ${!powerTop10 ? "text-black" : "text-muted"}`}
                    onClick={() => setPowerTop10(false)}
                    title="Bottom 10"
                  >
                    &darr; Bottom 10
                  </i>
                </div>
                <DahsobardTwoBarChart
                  data={power.map((d) => ({ x: d.x || "Others", y: d.y }))}
                  // data={[{}, {}]}
                  colors={["#0d6efd"]}
                  name="KW"
                  // title="Total Power by Building"
                  viewType="monthly"
                  xAxisName="Location Name"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-3 text-center">
                <h4>Total Consumption by Building, kWh</h4>
                <h6>(Monthly)</h6>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i
                    className={`cursor-pointer ${energyTop10 ? "text-black" : "text-muted"}`}
                    onClick={() => setEnergyTop10(true)}
                    title="Top 10"
                  >
                    Top 10 &uarr;
                  </i>
                  <i
                    className={`cursor-pointer ${!energyTop10 ? "text-black" : "text-muted"}`}
                    onClick={() => setEnergyTop10(false)}
                    title="Bottom 10"
                  >
                    &darr; Bottom 10
                  </i>
                </div>
                <DahsobardTwoBarChart
                  data={consumption.map((d) => ({ x: d.x || "Others", y: d.y }))}
                  // data={[{}, {}]}
                  colors={["#198754"]}
                  name="kWh"
                  // title="Total Consumption by Building"
                  viewType="monthly"
                  xAxisName="Location Name"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-3 text-center">
                <h4>Energy Sources by Percentage</h4>
                <h6 style={{ visibility: "hidden" }}>(Total No. Of Building/Sites)</h6>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    visibility: "hidden"
                  }}
                >
                  <i
                    className={`cursor-pointer ${sourceTop10 ? "text-black" : "text-muted"}`}
                    onClick={() => setSourceTop10(true)}
                    title="Top 10"
                  >
                    Top 10 &uarr;
                  </i>
                  <i
                    className={`cursor-pointer ${!sourceTop10 ? "text-black" : "text-muted"}`}
                    onClick={() => setSourceTop10(false)}
                    title="Bottom 10"
                  >
                    &darr; Bottom 10
                  </i>
                </div>

                <BarChart
                  data={source}
                  // data={[{}, {}]}
                  colors={["#dc3545", "#ffc107"]}
                  name="Percentage(%)"
                  // title="Energy Sources by Percentage"
                  viewType="monthly"
                  xAxisName="Energy Source Type"
                  yAxisMax={100}
                  yAxisSuffix="%"
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="card p-3">
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="mb-4">Site Locations Summary</h5>
                    <div className="row align-items-center">
                      <div className="col-md-4">
                        <h6>Total No. Building/Site</h6>
                      </div>
                      <div className="col-md-8 d-flex my-1">
                        <p className="px-5 m-0"> : </p>
                        <p className="m-0 bg-success text-white w-50 text-center py-1 fw-bold">
                          {" "}
                          {summary.building || 0}
                        </p>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-4">
                        <h6>Total No. Installer</h6>
                      </div>
                      <div className="col-md-8 d-flex my-1">
                        <p className="px-5 m-0"> : </p>
                        <p className="m-0 bg-blue text-white w-50 text-center py-1 fw-bold">
                          {" "}
                          {summary.installer || 0}
                        </p>
                      </div>
                    </div>
                    {/* <div className="row">
                                            <div className="col-md-4">
                                                <h6>Total No. Devices</h6>
                                            </div>
                                            <div className="col-md-8">
                                                <p className='m-0'>: {summary.device || 0}</p>
                                            </div>
                                        </div> */}
                  </div>
                  <div className="col-md-6">
                    <h5 className="mb-4">Solar Thermal</h5>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row align-items-center">
                          <div className="col-md-4">
                            <h6>Total No. Building/Site</h6>
                          </div>
                          <div className="col-md-8 d-flex my-1">
                            <p className="px-5 m-0"> : </p>
                            <p className="m-0 bg-success text-white w-50 text-center py-1 fw-bold">
                              {" "}
                              {summary.solarThermalBuilding || 0}
                            </p>
                          </div>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-md-4">
                            <h6>Total No. Installer</h6>
                          </div>
                          <div className="col-md-8 d-flex my-1">
                            <p className="px-5 m-0"> : </p>
                            <p className="m-0 bg-blue text-white w-50 text-center py-1 fw-bold">
                              {" "}
                              {summary.solarThermalInstaller || 0}
                            </p>
                          </div>
                        </div>
                        {/* <div className="row">
                                                    <div className="col-md-4">
                                                        <h6>Total No. Devices</h6>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <p className='m-0'>: {summary.device || 0}</p>
                                                    </div>
                                                </div> */}
                        <div className="row align-items-center">
                          <div className="col-md-4">
                            <h6>Total Capacity (kWp)</h6>
                          </div>
                          <div className="col-md-8 d-flex my-1">
                            <p className="px-5 m-0"> : </p>
                            <p className="m-0 bg-danger text-white w-50 text-center py-1 fw-bold">
                              {" "}
                              {summary.capacity || 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FullScreen>
      </div>
    </div>
  );
};

export default DashboardTwo;
