import { notification, Popconfirm, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FiEdit, FiTrash } from "react-icons/fi";
import { api, endpoint } from "../../api";
import { ThemeContext } from "../../App";
import { EditSiteDocumentURL } from "../../components/Modals/EditSiteDocumentURL";
import { userRole } from "../../constant";

export const SiteUrl = ({ siteLocationId }) => {
  let { isDark } = useContext(ThemeContext);

  const [isLoading, setLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isListLoading, setListLoading] = useState(false);
  const defaultData = {
    name: "",
    type: "Link",
    media: "",
    site: siteLocationId,
  };
  const [urlData, setUrlData] = useState(defaultData);
  const [urlList, setUrlList] = useState([]);
  const [colShow, setColShow] = useState(false);
  const [editData, setEditData] = useState({});

  const getUrls = async () => {
    setListLoading(true);
    try {
      let response = await api.get(`/documents/${siteLocationId}/Link`, urlData);
      response = response.map((item, idx) => {
        item.sl = idx + 1;
        return item;
      });
      setUrlList(response);
    } catch (error) {}
    setListLoading(false);
  };

  useEffect(() => {
    getUrls();
    // eslint-disable-next-line
  }, []);

  const handleCreate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post("/documents", urlData);
      notification.success({
        message: "URL has been added.",
        placement: "bottomLeft",
      });
      setUrlData(defaultData);
      getUrls();
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setLoading(false);
  };
  //   console.log(urlData);
  const handleInputChange = (e) => {
    setUrlData({ ...urlData, [e.target.name]: e.target.value });
  };
  const handleDeleteUrl = async (urlId) => {
    setIsDeleteLoading(true);
    try {
      const { message } = await api.delete(`${endpoint.site_url}/${urlId}`, urlData);
      notification.success({
        message,
        placement: "bottomLeft",
      });
      getUrls();
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsDeleteLoading(false);
  };
  const columns = [
    {
      name: "No.",
      cell: ({ sl }) => sl,
      width: "60px",
      center: true,
    },
    {
      name: "Name",
      cell: ({ name }) => name,
    },
    {
      name: "URL Address",
      selector: ({ media }) => media,
      width: "120px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="actions">
          <button className="btn btn-info me-1" onClick={() => setEditData(row)}>
            <FiEdit />
          </button>
          <Popconfirm
            title="Are you sure?"
            okButtonProps={{
              className: "btn btn-danger",
              loading: isDeleteLoading,
            }}
            onConfirm={() => {
              handleDeleteUrl(row._id);
            }}
          >
            <button className="btn btn-danger">
              <FiTrash />
            </button>
          </Popconfirm>
        </div>
      ),
      center: true,
      //   omit: colShow,
    },
  ];
  //   useEffect(() => {
  //     if (userDetails?.role !== userRole.superAdmin) {
  //     } else {
  //       setColShow(true);
  //     }
  //   }, [userDetails]);
  return (
    <div className="row my-5 pb-3 gx-5">
      <div className="col-md-6">
        <h5 className="mt-2 mb-3">Add URL</h5>
        <Spin spinning={isLoading}>
          <form onSubmit={handleCreate}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                URL Name
              </label>
              <input
                type="text"
                name="name"
                value={urlData.name}
                onChange={handleInputChange}
                className="form-control"
                id="name"
                placeholder="Enter diagram name"
                required
              />
            </div>
            <div className="mb-2">
              <label htmlFor="media" className="form-label">
                URL Address
              </label>
              <input
                type="text"
                name="media"
                value={urlData.media}
                onChange={handleInputChange}
                className="form-control"
                id="media"
                placeholder="URL Address"
                required
              />
            </div>
            <div className="d-flex justify-content-end mt-4">
              <button className="btn btn-success">Add</button>
            </div>
          </form>
        </Spin>
      </div>
      <div className="col-md-6">
        <div className="mt-4 pt-2">
          <Spin spinning={isListLoading}>
            <DataTable
              columns={columns}
              data={urlList}
              pagination
              striped={!isDark}
              noDataComponent={isListLoading ? "Loading" : <p className="font-bold">No record found.</p>}
              theme={isDark ? "dark" : "light "}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 50]}
            />
          </Spin>
        </div>
      </div>
      <EditSiteDocumentURL
        show={!!editData?._id}
        onHide={() => {
          setEditData({});
        }}
        editData={editData}
        getUrls={getUrls}
      />
    </div>
  );
};
