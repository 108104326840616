import { encryptTransform } from "redux-persist-transform-encrypt";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import userSlice from "./userSlice";
import dashboardSlice from "./dashboardSlice";

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_REDUX_STORAGE_SECRET_KEY,
  onError: function (error) {
    console.log(error);
  },
});

const persistConfig = {
  key: "root",
  storage: storage,
  timeout: null,
  transforms: [encryptor],
};

const appReducer = combineReducers({
  user: persistReducer(persistConfig, userSlice.reducer),
  dashboard: dashboardSlice.reducer,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === "user/setIsLogged" && !action.payload) {
    state = initialState;
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

export const persistor = persistStore(store);
