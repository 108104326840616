import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

/**
 * -use request class like this
 * @constructor constructor({ version: 'v2' })
 */
export default class RestAPI {
  constructor({ version }) {
    if (version === "v2") {
      this.baseUrl = BASE_URL + "/api/v2";
    } else {
      this.baseUrl = BASE_URL;
    }
  }
  get = (url, config = {}) => {
    const endpoint = this.baseUrl + url;
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint, {
          withCredentials: true,
          ...config,
        })
        .then((res) => resolve(res.data, res.status))
        .catch(reject);
    });
  };
  put = (url, payload, config = {}) => {
    const endpoint = this.baseUrl + url;
    return new Promise((resolve, reject) => {
      axios
        .put(endpoint, payload, {
          withCredentials: true,
          ...config,
        })
        .then((res) => resolve(res.data, res.status))
        .catch(reject);
    });
  };
  post = (url, payload, config = {}) => {
    const endpoint = this.baseUrl + url;
    return new Promise((resolve, reject) => {
      axios
        .post(endpoint, payload, {
          withCredentials: true,
          ...config,
        })
        .then((res) => resolve(res.data, res.status))
        .catch(reject);
    });
  };
  delete = (url, config = {}) => {
    const endpoint = this.baseUrl + url;
    return new Promise((resolve, reject) => {
      axios
        .delete(endpoint, {
          withCredentials: true,
          ...config,
        })
        .then((res) => resolve(res.data, res.status))
        .catch(reject);
    });
  };
}

export const api = new RestAPI({ version: "v1" });
