import { GRAPH_TYPE } from "../constant";

export const graphTitle = {
  POWER: "Power Graph",
  ENERGY_CONSUMPTION: "Energy Consumption",
  BUILDING_ENERGY_INDEX: "Building Energy Index",
  CO2_EMISSION: "CO2 Emission",
};

export const useGraphType = (dashboardType) => {
  const options = Object.entries(GRAPH_TYPE).map(([key, value]) => {
    return {
      name: graphTitle[value],
      value: value,
    };
  });
  switch (dashboardType) {
    case 2:
      return options.filter(({ value }) => [GRAPH_TYPE.POWER, GRAPH_TYPE.ENERGY_CONSUMPTION].includes(value));
    case 3:
      return options.filter(({ value }) =>
        [GRAPH_TYPE.POWER, GRAPH_TYPE.ENERGY_CONSUMPTION, GRAPH_TYPE.BUILDING_ENERGY_INDEX].includes(value)
      );
    default:
      return options;
  }
};
