import { notification, Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { api, endpoint } from "../../api";
import NotificationSidebar from "../../components/Notifications/NotificationSidebar";

const CreateAlarm = () => {
  const navigation = useNavigate();
  const userDetails = useSelector((state) => state?.user?.userDetails);

  const [isLoading, setIsLoading] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState();
  const [ErrorMessage, setErrorMessage] = useState();
  const [siteLocations, setSiteLocations] = useState([]);
  const [deviceParameters, setDeviceParameters] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deviceType, setDeviceType] = useState('');
  const [CreateAlarmData, setCreateAlarmData] = useState({
    name: "",
    type: "email",
    site: "",
    device: "",
    parameter: "",
    option: "",
    max: "",
    min: "",
    interval: "",
  });

  const getSiteLocations = async (userDetails) => {
    if (userDetails.role === "superAdmin") {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/site-location`,
        { withCredentials: true }
      );
      if (response) {
        setSiteLocations(
          response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        );
      }
    } else if (userDetails.role === "admin") {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/site-location/admin-sites/` +
        userDetails._id,
        { withCredentials: true }
      );
      if (response) {
        setSiteLocations(
          response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        );
      }
    } else if (userDetails.role === "installer") {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/site-location/installer-sites/` +
        userDetails._id,
        { withCredentials: true }
      );
      if (response) {
        setSiteLocations(
          response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        );
      }
    }
  };

  useEffect(() => {
    document.title = "SEDA - Create New Alarm";
  }, []);
  useEffect(() => {
    getSiteLocations(userDetails);
    // eslint-disable-next-line
  }, [userDetails]);


  const { name, type, site, device, parameter, option, min, max, interval } =
    CreateAlarmData;
  const onInputChange = (e) => {
    setCreateAlarmData({ ...CreateAlarmData, [e.target.name]: e.target.value });
  };

  const getDevices = async (locationId) => {
    const response = await axios.get(`/device`, {
      withCredentials: true,
      params: { site: locationId }
    });
    if (response) {
      setDevices(response.data.results);
    }
  };


  // device type
  useEffect(() => {
    if (CreateAlarmData.device) {
      const device = devices.find(device => device._id === CreateAlarmData.device)
      setDeviceType(device?.deviceType?.name || '')
    }

  }, [site, device])



  useEffect(() => {
    if (site) {
      getDevices(site);
    }
    // eslint-disable-next-line
  }, [site]);

  const getDeviceParameters = async (deviceId) => {
    try {
      const { results } = await api.get(endpoint.get_device_parameter, {
        params: {
          deviceId,
          limit: 1000,
          page: 1,
          sortBy: "name",
          orderBy: "ASC",
          type: "formula",
        },
      });
      setDeviceParameters(results);
    } catch (error) { }
  };

  useEffect(() => {
    if (device) {
      getDeviceParameters(device);
    }
  }, [device]);

  const submitHandler = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    // return console.log({CreateAlarmData})
    try {
      await api.post(endpoint.notification, {
        ...CreateAlarmData,
        min: Number(CreateAlarmData.min || 0),
        max: Number(CreateAlarmData.max || 0),
        interval: Number(CreateAlarmData.interval || 0),
      });
      navigation("/alarm-summary");
      notification.info({
        message: "Notification has been created.",
        placement: "bottomLeft",
      });
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);

    // const response = await axios
    //   .post(`${process.env.REACT_APP_API_URL}/notification`, CreateAlarmData, {
    //     withCredentials: true,
    //   })
    //   .catch(function (error) {
    //     if (error.response) {
    //       setIsLoading(false);
    //       setErrorMessage(error.response.data);
    //       setTimeout(() => {
    //         setErrorMessage();
    //       }, 2000);
    //     }
    //   });
    // const data = response.data;
    // if (data) {
    //   setIsLoading(false);
    //   setSuccessMessage("Alarm created successfully");
    //   setCreateAlarmData({
    //     name: "",
    //     type: "",
    //     site: "",
    //     device: "",
    //     parameter: "",
    //     option: "",
    //     interval: "",
    //     min: "",
    //     max: "",
    //   });
    //   setTimeout(() => {
    //     setSuccessMessage();
    //     navigation("/alarm-summary");
    //   }, 2000);
    // }
  };
  return (
    <div className="alarm-page">
      <div className="container-fluid">
        <div className="row my-5 vh60">
          <div className="col-md-2">
            <NotificationSidebar />
          </div>
          <div className="col-md-10">
            <div className="card p-3 mb-3">
              <h3>Create New Alarm</h3>
              {/* <div className="d-flex justify-content-center">
                {isLoading && <Spinner animation="border" variant="dark" />}
              </div> */}
              {SuccessMessage && (
                <div className="alert alert-success" role="alert">
                  {SuccessMessage}{" "}
                </div>
              )}
              {ErrorMessage && (
                <div className="alert alert-danger" role="alert">
                  {ErrorMessage}{" "}
                </div>
              )}
              <Spin spinning={isLoading}>
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Alarm Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={name}
                          onChange={onInputChange}
                          className="form-control"
                          id="name"
                          placeholder="Enter Alarm Name"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="type" className="form-label">
                          Alarm Type
                        </label>
                        <select
                          name="type"
                          id="type"
                          // defaultValue={type}
                          className="form-select"
                          onChange={onInputChange}
                        >
                          <option selected disabled> Select Alarm Type</option>
                          {/* <option value="message">
                          System Warning Message Only
                        </option> */}
                          <option value="email">Email Notification Only</option>
                          {/* <option value="both">
                          Email Notification + System Warning Message
                        </option> */}
                        </select>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="site" className="form-label">
                          Site Location
                        </label>
                        <select
                          name="site"
                          id="site"
                          defaultValue={site}
                          className="form-select"
                          onChange={onInputChange}
                        >
                          <option> Select Site Location</option>
                          {siteLocations?.length &&
                            siteLocations.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="device" className="form-label">
                          Device
                        </label>
                        <select
                          name="device"
                          id="device"
                          defaultValue={device}
                          className="form-select"
                          onChange={onInputChange}
                        >
                          <option> Select Device</option>
                          {devices?.length &&
                            devices.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="parameter" className="form-label">
                          {deviceType.toLowerCase() === 'virtual' ? "Formula" : "Device Parameter"}
                        </label>
                        <select
                          name="parameter"
                          id="parameter"
                          defaultValue={parameter}
                          className="form-select"
                          onChange={onInputChange}
                        >
                          <option> Select  {deviceType.toLowerCase() === 'virtual' ? "Formula" : "Device Parameter"}</option>
                          {deviceParameters?.length &&
                            deviceParameters.map((item) => (
                              <option value={item._id} key={item?._id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>



                      <div className="mb-3">
                        <label htmlFor="option" className="form-label">
                          Alarm Notification
                        </label>
                        <select
                          name="option"
                          id="option"
                          className="form-select"
                          defaultValue={option}
                          onChange={onInputChange}
                        >
                          <option>Select Alarm Notification Option</option>
                          <option value="min">Min</option>
                          <option value="max">Max</option>
                          <option value="range">Range</option>
                        </select>
                      </div>
                      <div className="row mb-3">
                        {option === "min" && (
                          <div className="col-md-12">
                            <label htmlFor="value" className="form-label">
                              Set Value
                            </label>
                            <input
                              type="number"
                              name="min"
                              value={min}
                              className="form-control"
                              id="value"
                              placeholder="Enter minimum value to trigger alarm"
                              onChange={onInputChange}
                              required
                            />
                          </div>
                        )}
                        {option === "max" && (
                          <div className="col-md-12">
                            <label htmlFor="value" className="form-label">
                              Set Value
                            </label>
                            <input
                              type="number"
                              name="max"
                              value={max}
                              className="form-control"
                              id="value"
                              placeholder="Enter maximum value to trigger alarm"
                              onChange={onInputChange}
                              required
                            />
                          </div>
                        )}
                        {option === "range" && (
                          <>
                            <div className="col-md-6">
                              <label htmlFor="value" className="form-label">
                                Start Value
                              </label>
                              <input
                                type="number"
                                name="min"
                                value={min}
                                className="form-control"
                                id="value"
                                placeholder="Enter minmum value to trigger alarm"
                                onChange={onInputChange}
                                required
                              />
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="value" className="form-label">
                                End Value
                              </label>
                              <input
                                type="number"
                                name="max"
                                value={max}
                                className="form-control"
                                id="value"
                                placeholder="Enter maximum value to trigger alarm"
                                onChange={onInputChange}
                                required
                              />
                            </div>
                          </>
                        )}
                        {/* <div className="col-md-6"></div> */}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="interval" className="form-label">
                          Set Trigger Interval
                        </label>
                        <select
                          name="interval"
                          id="interval"
                          className="form-select"
                          defaultValue={interval}
                          onChange={onInputChange}
                        >
                          <option>Select Alarm Trigger Interval</option>
                          <option value="1">1 min</option>
                          <option value="15">15 min</option>
                          <option value="30">30 min</option>
                          <option value="60">1 hour</option>
                          <option value="720">12 hours</option>
                          <option value="1440">24 hours</option>
                        </select>
                      </div>
                      <div className="mb-3 float-end">
                        <button className="btn btn-success me-2" type="submit">
                          Create Alarm
                        </button>
                        <Link to="/alarm-summary" className="btn btn-secondary">
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAlarm;
