import React from "react";

export const GenericSelect = ({
  name,
  label,
  value,
  placeholder,
  handleInput,
  wrapperClassName,
  options,
  required
}) => {
  return (
    <div className={`w-100 ${wrapperClassName}`}>
      <label className="form-label">{label}</label>
      <select
        value={value || ""}
        className="form-select"
        required={required || false}
        onChange={(e) => {
          // console.log(e.target.value, name);
          handleInput({ name, value: e.target.value });
        }}
      >
        <option value={""} disabled>
          {placeholder}
        </option>
        {options.map(({ value, name }, idx) => (
          <option key={idx} value={value}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
};
