import React from "react";
import AdminSidebarNav from "../../components/Admins/AdminSidebarNav";

import { Outlet } from "react-router-dom";

const SiteLocations = () => {
  return (
    <div className="users">
      <div className="container-fluid">
        <div className="row my-5 vh60">
          <div className="col-md-2">
            <AdminSidebarNav />
          </div>

          <div className="col-md-10">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteLocations;
