import { memo } from "react";
import React, { useEffect, useState } from "react";
import LineChart from "../../components/Charts/LineChart";
import { FullScreen } from "react-full-screen";
import AreaChart from "../../components/Charts/AreaChart";
import { useSelector } from "react-redux";
import PieChart from "../../components/Charts/PieChart";
import { CounterBox } from "../../components/Dashboard/CounterBox";
import { OverallStats } from "../../components/Dashboard/OverallStats";
import { TimeFilter } from "../../components/Dashboard";
import { api, endpoint } from "../../api";
import { Spin } from "antd";
import SplineChart from "../../components/Charts/SplineChart";

const Dashboard = memo(({ handle }) => {
  const [viewType, setViewType] = useState("today");
  const [xAxisName, setXAxisName] = useState("Hours");
  const [dateRange, setDateRange] = useState([]);
  const [areaChartLoading, setAreachartLoading] = useState(false);
  const [pieLoading, setPieLoading] = useState(false);
  const [counterLoading, setCounterLoading] = useState(false);
  const [lineLoading, setLineLoading] = useState(false);
  const [areachartData, setAreachartData] = useState({});
  const [lineChartData, setLineChartData] = useState({});
  const [counters, setCounters] = useState({});
  const [pieChartData, setPieChartData] = useState([
    {
      value: 1,
      name: "No data to show",
    },
  ]);
  // redux
  let { currentDevice, currentSite } = useSelector((state) => state.user || {});

  const siteId = currentSite?._id;
  // console.log(currentSite)
  // pulling baseload for graph 1
  // const getBaseload = async () => {
  //   try {
  //     const { result } = await api.get(endpoint.get_dashboard_setting, {
  //       params: { dashboardType: 1 },
  //     });
  //     setBaseload(result.baseLoad);
  //   } catch (error) {}
  // };
  // pulling counters
  const getCounterData = async (params) => {
    setCounterLoading(true);
    try {
      const response = await api.get(endpoint.get_dashboard_counter, {
        params,
      });
      setCounters(response);
    } catch (error) {}
    setCounterLoading(false);
  };
  // pulling pie chart
  const getPieChartData = async (params) => {
    delete params.deviceId;
    setPieLoading(true);
    try {
      const { results } = await api.get(endpoint.get_consumption_by_device, {
        params,
      });
      setPieChartData(results);
    } catch (error) {
      console.log(error);
    }
    setPieLoading(false);
  };
  // pulling area chart data
  const getAreaChartData = async (params) => {
    setAreachartLoading(true);
    try {
      const response = await api.get(endpoint.get_power_consumption, {
        params,
      });
      setAreachartData(response);
    } catch (error) {}
    setAreachartLoading(false);
  };
  // pulling line chart data
  const getLineChartData = async (params) => {
    setLineLoading(true);
    try {
      const response = await api.get(endpoint.get_energy_consumption, {
        params,
      });
      setLineChartData(response);
    } catch (error) {}
    setLineLoading(false);
  };
  // makes the query params for filters and calling all get api's
  const getChartData = async () => {
    console.log({ viewType });
    if (viewType === "today") setXAxisName("Hours");
    else if (viewType === "custom") setXAxisName("Days");
    else if (viewType === "weekly") setXAxisName("Days");
    else if (viewType === "monthly") setXAxisName("Months");
    const params = {
      deviceId: currentDevice?._id,
      siteId: currentSite?._id,
      type: viewType,
    };
    if (viewType === "custom") {
      if (dateRange?.length) {
        params.startDate = dateRange[0].format("YYYY-M-D");
        params.endDate = dateRange[1].format("YYYY-M-D");
      } else {
        // setAreachartData({ results: [] });
        // setLineChartData({ results: [] });
        return;
      }
    }
    getAreaChartData(params);
    getLineChartData(params);
    getCounterData({
      deviceId: currentDevice?._id,
      power: "kw",
      energy: "kwh",
      buildingEnergyIndex: "kwh/m2",
      CO2Emission: "kg",
    });
  };

  useEffect(() => {
    if (siteId) {
      const params = {
        siteId,
        type: viewType,
      };
      if (viewType === "custom") {
        if (dateRange?.length === 2) {
          params.startDate = dateRange[0].format("YYYY-M-D");
          params.endDate = dateRange[1].format("YYYY-M-D");
        }
      }
      getPieChartData(params);
    }
  }, [siteId, viewType]);

  useEffect(() => {
    getChartData();
  }, [currentDevice, viewType, dateRange]);

  useEffect(() => {
    document.title = "SEDA - Dashboard";
    // getBaseload();
  }, [viewType]);

  useEffect(() => {
    document.title = "SEDA - Dashboard";
    const interval = setInterval(() => {
      getChartData();
    }, 60000);
    return () => clearInterval(interval);
  }, [viewType, currentDevice, currentSite, dateRange]);

  return (
    <div className="dashboard">
      <div className="container-fluid">
        <FullScreen handle={handle}>
          <div className="row">
            <div className="col-md-12">
              <Spin spinning={areaChartLoading}>
                <div className="row mt-4">
                  <div className="col-md-2 mt-5">
                    <CounterBox
                      title="This Month Consumption"
                      unit={"kWh"}
                      value={counters?.thisMonthConsumption || 0}
                    />
                    <CounterBox
                      title={
                        <span>
                          This Month CO<sub>2</sub> emissions
                        </span>
                      }
                      unit={
                        <span>
                          kgCO<sub>2</sub>
                        </span>
                      }
                      value={counters?.thisMonthEmission || 0}
                    />
                  </div>
                  <div className="col-md-8">
                    <TimeFilter
                      viewType={viewType}
                      setViewType={setViewType}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                    />
                    <div className="row">
                      <div className="col-md-10">
                        <button onClick={() => getChartData()} to="#" className="btn btn-success ms-2 mb-2">
                          Refresh
                        </button>
                        <AreaChart
                          data={areachartData?.results || []}
                          name="Power (kW)"
                          title="Load Profile (kW)"
                          xAxisName={xAxisName}
                          viewType={viewType}
                          dateRange={dateRange}
                          baseload={currentSite?.baseLoad || 0}
                        />
                        {/* <SplineChart
                          data={[{ name: "Power (KW)", data: areachartData?.results || [] }]}
                          name="Power (kW)"
                          yAxis={{ title: "Power (KW)" }}
                          baseload={baseload}
                        /> */}
                      </div>
                      <div className="col-md-2">
                        <OverallStats
                          max={areachartData?.max || 0}
                          min={areachartData?.min || 0}
                          avg={areachartData?.avg || 0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 mt-5">
                    <CounterBox title="Today Consumption" unit={"kWh"} value={counters?.todayConsumption || 0} />
                    <CounterBox
                      title={
                        <span>
                          Today CO<sub>2</sub> emissions
                        </span>
                      }
                      unit={
                        <span>
                          kgCO<sub>2</sub>
                        </span>
                      }
                      value={counters?.todayEmission || 0}
                    />
                  </div>
                </div>
              </Spin>
              <Spin spinning={lineLoading}>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <LineChart
                      name="kWh"
                      data={lineChartData?.results || []}
                      title="Energy Consumption (kWh)"
                      xAxisName={xAxisName}
                      viewType={viewType}
                      dateRange={dateRange}
                      xTitleClassName="mt-0"
                    />
                  </div>
                  <div className="col-md-2">
                    <OverallStats
                      max={lineChartData?.max || 0}
                      min={lineChartData?.min || 0}
                      avg={lineChartData?.avg || 0}
                    />
                  </div>
                  <div className="col-md-4">
                    {/* <h6 className='text-center mb-3'>Device (kWh)</h6> */}
                    <PieChart data={pieChartData} />
                  </div>
                </div>
              </Spin>
            </div>
          </div>
        </FullScreen>
      </div>
    </div>
  );
});

export default Dashboard;
