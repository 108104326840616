import React, { useState } from "react";
import { AssignDashboard } from "./AssignDashboard";
import { GeneralSettings } from "./GeneralSettings";
import { SectionHeader } from "./SectionHeader";
import "./SelectedDashboardSettings.css";
import Button from "react-bootstrap/Button";
import Dashboard4Modal from "../../../components/Modals/Dashboard4Modal";
import { useDispatch, useSelector } from "react-redux";
import { updateDashboardDetails, updateDashboard4Card } from "../../../redux/dashboardSlice";
import { Dashboard4Board } from "./Dashboard4Board";

const SelectedDashboardSettings4 = ({ handleChangeTemplate, dashboardType }) => {
  const dispatch = useDispatch();
  const { backgroundImage, cards, ...dashboardDetails } = useSelector((state) => state.dashboard[4]);

  const [cardModal, setCardModal] = useState();

  const handleEBFileUpload = (file) => {
    console.log(URL.createObjectURL(file))
    if (file.type === "application/pdf") {
      dispatch(
        updateDashboardDetails({
          dashboardType: 4,
          backgroundImage: "/images/pdfLogo.png",
          backgroundImageFile: file,
        })
      );
    } else {
      dispatch(
        updateDashboardDetails({
          dashboardType: 4,
          backgroundImage: URL.createObjectURL(file),
          backgroundImageFile: file,
        })
      );
    }
  };

  return (
    <div className="selectedDashboardSettings border-success">
      <h3 className="fw-bold mb-4">{dashboardDetails?.name || "Dashboard 1"}</h3>
      <SectionHeader text="General Settings" small={false} />
      <GeneralSettings dashboardDetails={dashboardDetails} handleChangeTemplate={handleChangeTemplate} />

      {dashboardDetails?.userType === "all" && (
        <>
          <SectionHeader text="Assign Dashboard" showUnderline={false} className="mt-3 mb-3" />
          <AssignDashboard dashboardType={dashboardType} dashboard={dashboardDetails?._id} />
        </>
      )}
      <SectionHeader text="Template Settings" small={false} className="mb-4" />

      <div className="mb-4">
        <Button variant="success" onClick={() => setCardModal(true)}>
          Add Items
        </Button>

        <label className="btn btn-primary mx-3" htmlFor="fileUpload">
          Upload Background Image
          <input
            className="form-control"
            accept="image/*,.pdf"
            type="file"
            id="fileUpload"
            style={{ display: "none" }}
            onChange={(e) => handleEBFileUpload(e.target.files[0])}
          />
        </label>
      </div>

      <Dashboard4Board editable={true} setCardModal={setCardModal} />

      <Dashboard4Modal
        cardModal={cardModal}
        setCardModal={setCardModal}
        data={cards}
        onSave={(item, mode) => {
          console.log(item, mode);
          if (mode === "edit") {
            dispatch(updateDashboard4Card({ ...item }));
          } else {
            dispatch(
              updateDashboardDetails({
                dashboardType: 4,
                cards: [...cards, item],
              })
            );
          }
        }}
      />
    </div>
  );
};

export default SelectedDashboardSettings4;
