import { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

export const DeviceFormulaOperation = ({
  closeable = false,
  onClose = () => { },
  onChange = () => { },
  operation = { option: "", value: "" },
  parameters = [],
  formulas = [],
  disabled = false,
  width = "150px",
}) => {
  const [option, setOption] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    setOption(operation.option);
    setValue(operation.value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation]);

  return (
    <div className="d-flex flex-column gap-2 " style={{ width, float: "left", position: "relative" }}>
      {closeable && (
        <AiFillCloseCircle style={{ position: "absolute", right: -5, top: -10, cursor: "pointer" }} onClick={onClose} />
      )}

      <input
        type="text"
        name="option"
        id="option"
        value={operation.value}
        className="form-control text-capitalize"
        disabled
        style={{ boxShadow: "none" }}
      />

      <div style={{ width: "100%" }}>
        {(() => {
          switch (option) {
            case "parameter":
              return (
                <select
                  name="option"
                  id="option"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange({ value: e.target.value, parameter: e.target.selectedOptions[0].id });
                  }}
                  className="form-select"
                  style={{ boxShadow: "none" }}
                  disabled={disabled}
                >
                  <option>Select System Parameter</option>
                  {parameters?.length > 0 &&
                    parameters.map((item, index) => (
                      <option value={item.value} key={parameters._id} id={item._id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              );
            case "operator":
              return (
                <select
                  name="option"
                  id="option"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange({ value: e.target.value });
                  }}
                  className={disabled ? "form-control" : "form-select"}
                  style={{ boxShadow: "none" }}
                  disabled={disabled}
                >
                  <option>Select Operator</option>
                  <option value="+">+</option>
                  <option value="-">-</option>
                  <option value="x">x</option>
                  <option value="/">/</option>
                  <option value="(">(</option>
                  <option value=")">)</option>
                  <option value="=">=</option>
                </select>
              );
            case "formula":
              return (
                <select
                  name="option"
                  id="option"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange({ value: e.target.value, formula: e.target.selectedOptions[0].id });
                  }}
                  className="form-select"
                  style={{ boxShadow: "none" }}
                  disabled={disabled}
                >
                  <option>Select Formula</option>
                  {formulas?.length > 0 &&
                    formulas.map((item, index) => (
                      <option value={item.name} key={index} id={item._id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              );

            case "time":
            case "area":
              return (
                <input
                  type="text"
                  name="option"
                  id="option"
                  value={option}
                  className="form-control text-capitalize"
                  disabled
                  style={{ boxShadow: "none" }}
                />
              );
            default:
              return (
                <input
                  type="text"
                  name="value"
                  id="value"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange({ value: e.target.value });
                  }}
                  className="form-control"
                  placeholder="Enter a value"
                  style={{ boxShadow: "none" }}
                  disabled={disabled}
                />
              );
          }
        })()}
      </div>
    </div>
  );
};
