import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { api, endpoint } from "../../api";

const Dashboard4Modal = ({ cardModal, setCardModal, onSave }) => {
  const mode = typeof cardModal === "object" ? "edit" : "create";
  const initialState = {
    _id: new Date().getTime(),
    device: "",
    site: "",
    title: "",
    parameter: "",
    operation: "none",
    period: "realtime",
    backgroundColor: "#ff0011",
    textColor: "#ffffff",
    position: {
      x: 0,
      y: 0,
    },
  };

  const { userSites } = useSelector((state) => state.user);

  const [deviceParameters, setDeviceParameters] = useState([]);
  const [devices, setDevices] = useState([]);
  const [state, setState] = useState(initialState);
  const [parameterLoading, setParameterLoading] = useState(false);

  useEffect(() => {
    // console.log(mode);
    if (mode === "edit") {
      setState({
        _id: cardModal._id,
        title: cardModal.title,
        device: cardModal.device,
        site: cardModal.site,
        parameter: cardModal.parameter,
        backgroundColor: cardModal.backgroundColor,
        textColor: cardModal.textColor,
        position: cardModal.position,
        period: cardModal.period,
        operation: cardModal.operation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  // api functions
  const getDeviceParameters = async (device) => {
    setDeviceParameters([]);
    setParameterLoading(true);
    try {
      const { results } = await api.get(endpoint.get_device_parameter, {
        params: { limit: 1000, page: 1, sortBy: "name", orderBy: "ASC", deviceId: device, type: "formula" },
      });
      setDeviceParameters(results);
    } catch (error) {}
    setParameterLoading(false);
  };

  // handler
  const onInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const getDeviceList = async (locationId) => {
    setDevices([]);
    try {
      const { results } = await api.get(`/device`, {
        withCredentials: true,
        params: { site: locationId },
      });
      setDevices(results);
    } catch (error) {}
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const newItem = { ...state };
    onSave(newItem, mode);
    handleClose();
  };

  const handleClose = () => {
    setCardModal(false);
    setState(initialState);
  };

  useEffect(() => {
    if (state.site) {
      getDeviceList(state.site);
    }
  }, [state.site]);

  useEffect(() => {
    if (state.device) {
      getDeviceParameters(state.device);
    }
  }, [state.device]);

  // console.log(state);

  return (
    <Modal show={cardModal} onHide={handleClose} backdrop="static" centered>
      {/* <Form onSubmit={submitHandler}> */}
      <Modal.Header closeButton>
        <Modal.Title>Add New Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label htmlFor="site" className="form-label">
            Site Location
          </label>
          <select name="site" id="site" className="form-select" value={state.site} onChange={onInputChange} required>
            <option> Select Site Location</option>
            {userSites?.length &&
              userSites.map((item, index) => (
                <option value={item._id} key={index}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="device" className="form-label">
            Device
          </label>
          <select
            name="device"
            id="device"
            value={state.device}
            className="form-select"
            onChange={onInputChange}
            required
          >
            <option> Select Device</option>
            {devices?.length &&
              devices.map((item, index) => (
                <option value={item._id} key={index}>
                  {item?.name}
                </option>
              ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="parameter" className="form-label">
            Device Parameter
          </label>
          <select
            name="parameter"
            id="parameter"
            value={state.parameter}
            className="form-select"
            onChange={(e) => {
              setState({
                ...state,
                title: deviceParameters[e.target.selectedIndex - 1].name,
                parameter: e.target.value,
              });
            }}
            required
          >
            <option tabIndex={-1}> {parameterLoading ? "Loading" : "Select Device Parameter"} </option>
            {deviceParameters?.length &&
              deviceParameters.map((item) => (
                <option value={item._id} key={item?._id}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="parameter" className="form-label">
            Operation
          </label>
          <select
            name="operation"
            id="operation"
            value={state.operation}
            className="form-select"
            onChange={(e) => {
              setState({
                ...state,
                operation: e.target.value,
                period: e.target.value === "none" ? "realtime" : state.period,
              });
            }}
            required
          >
            <option value="none">None</option>
            <option value="sum">Sum</option>
            <option value="avg">Average</option>
            <option value="min">Minimum</option>
            <option value="max">Maximum</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="period" className="form-label">
            Period
          </label>
          <select
            name="period"
            id="period"
            value={state.period}
            className="form-select"
            onChange={(e) => {
              setState({
                ...state,
                period: e.target.value,
              });
            }}
            required
          >
            <option value="realtime"> Realtime</option>
            <option value="daily" disabled={state.operation === "none"}>
              Daily
            </option>
            <option value="weekly" disabled={state.operation === "none"}>
              Weekly
            </option>
            <option value="monthly" disabled={state.operation === "none"}>
              Monthly
            </option>
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">Title</label>
          <input
            className="form-control"
            name="title"
            type="text"
            value={state.title}
            placeholder="Enter Title"
            onChange={onInputChange}
            required
          />
        </div>

        <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
          <div className="mb-3 " style={{ display: "flex", flexDirection: "column" }}>
            <label htmlFor="backgroundColor" className="form-label">
              Background Color
            </label>
            <input
              type="color"
              id="backgroundColor"
              name="backgroundColor"
              className=""
              onChange={onInputChange}
              value={state.backgroundColor}
            />
          </div>
          <div className="mb-3 " style={{ display: "flex", flexDirection: "column" }}>
            <label htmlFor="textColor" className="form-label">
              Text Color
            </label>
            <input
              type="color"
              id="textColor"
              name="textColor"
              className=""
              onChange={onInputChange}
              value={state.textColor}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="success" onClick={submitHandler}>
          {mode === "edit" ? "Update" : "Create"}
        </Button>
      </Modal.Footer>
      {/* </Form> */}
    </Modal>
  );
};

export default Dashboard4Modal;
