import axios from "axios";
import { useEffect, useState } from "react";
import { endpoint } from "../api";

export const useSystemParameters = ({ page = 1, limit = 10000 } = {}) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      axios
        .get(endpoint.system_parameter, { withCredentials: true, params: { page, limit } })
        .then((res) => {
          setData(res.data);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading, error };
};
