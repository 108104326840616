import React, { useState, useEffect } from "react";
import { FullScreen } from "react-full-screen";
import AreaChart from "../../components/Charts/AreaChart";
import LineChart from "../../components/Charts/LineChart";
import { CounterBox } from "../../components/Dashboard";
import { api, endpoint } from "../../api";
import { useSelector } from "react-redux";
import { Spin } from "antd";

const DashboardThree = ({ handle }) => {
  const [areachartData, setAreachartData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [firstlineChartData, setFirstLineChartData] = useState({});
  const [secondLineChartData, setSecondLineChartData] = useState({});
  const [thirdLineChartData, setThirdLineChartData] = useState({});
  const [counters, setCounters] = useState({});

  // redux
  let { currentDevice, currentSite } = useSelector((state) => state?.user || {});

  // pulling counters
  const getCounterData = async (params) => {
    try {
      const response = await api.get(endpoint.get_dashboard_counter, {
        params,
      });
      setCounters(response);
    } catch (error) {}
  };

  const getFirstLineChartData = async (params) => {
    try {
      const response = await api.get(endpoint.get_energy_consumption, {
        params,
      });
      setFirstLineChartData(response);
    } catch (error) {}
  };
  const getSecondLineChartData = async (params) => {
    try {
      const response = await api.get(endpoint.get_energy_consumption, {
        params,
      });
      setSecondLineChartData(response);
    } catch (error) {}
  };
  const getThirdLineChartData = async (params) => {
    try {
      const response = await api.get(endpoint.get_building_energy_index, {
        params,
      });
      setThirdLineChartData(response);
    } catch (error) {}
  };
  // pulling area chart data
  const getAreaChartData = async (params) => {
    setLoading(true);
    try {
      const response = await api.get(endpoint.get_power_consumption, {
        params,
      });
      setAreachartData(response);
    } catch (error) {}
    setLoading(false);
  };

  const fetchData = () => {
    const params = {
      deviceId: currentDevice?._id,
      siteId: currentSite?._id,
      type: "monthly",
    };
    getAreaChartData({ ...params, type: "today" });
    getCounterData({
      deviceId: currentDevice?._id,
      power: "kw",
      energy: "kwh",
      buildingEnergyIndex: "kwh/m2",
      CO2Emission: "kg",
    });
    getFirstLineChartData({ ...params, type: "weekly" });
    getSecondLineChartData(params);
    getThirdLineChartData(params);
  };

  useEffect(() => {
    if (currentDevice) {
      fetchData();
    }
  }, [currentDevice]);

  useEffect(() => {
    document.title = "SEDA - Dashboard";
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, [currentDevice]);

  return (
    <div className="dashboard">
      <div className="container-fluid">
        <Spin spinning={isLoading}>
          <FullScreen handle={handle}>
            <div className="row mt-4 mb-3">
              <aside className="col-md-2">
                <CounterBox
                  title={
                    <span>
                      This month <br />
                      Consumption
                    </span>
                  }
                  unit={"kWh"}
                  value={counters?.thisMonthConsumption}
                />

                <CounterBox
                  title={
                    <span>
                      This month <br />
                      CO<sub>2</sub> emissions
                    </span>
                  }
                  unit={
                    <span>
                      kgCO<sub>2</sub>
                    </span>
                  }
                  value={counters?.thisMonthEmission}
                />

                <CounterBox title={"Max Power Monthly"} unit={"kW"} value={counters?.monthlyMaxPower} />
              </aside>
              <div className="col-md-8">
                <div className="row mb-2"></div>
                <div className="row">
                  <div className="col-md-6">
                    <h6 className="text-center">Real-time Power (kW)</h6>
                    <AreaChart
                      name="Power (kW)"
                      viewType={"today"}
                      data={areachartData?.results || []}
                      baseload={currentSite?.baseLoad || 0}
                      xAxisName="Hours"
                    />
                  </div>
                  <div className="col-md-6">
                    <h6 className="text-center">
                      Energy Consumption per day
                      <br />
                      (kWh/day)
                    </h6>
                    <LineChart
                      name="kWh"
                      data={firstlineChartData?.results || []}
                      title=""
                      viewType="weekly"
                      xAxisName="Days"
                      xTitleClassName="mt-0"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <h6 className="text-center">
                      Energy Consumption per month <br /> (kWh/month)
                    </h6>
                    <LineChart
                      name="kWh"
                      data={secondLineChartData?.results || []}
                      color="#00205b"
                      viewType="monthly"
                      xAxisName="Months"
                    />
                  </div>
                  <div className="col-md-6">
                    <h6 className="text-center">
                      Building Energy Index per month <br />
                      (kWh/m2)
                    </h6>
                    <LineChart
                      name="kWh/m2"
                      data={thirdLineChartData?.results || []}
                      viewType="monthly"
                      xAxisName="Months"
                    />
                  </div>
                </div>
              </div>
              <aside className="col-md-2">
                <CounterBox
                  title={
                    <span>
                      Today
                      <br />
                      Consumption
                    </span>
                  }
                  unit={"kWh"}
                  value={counters?.todayConsumption}
                />

                <CounterBox
                  title={
                    <span>
                      Today
                      <br />
                      CO<sub>2</sub> emissions
                    </span>
                  }
                  unit={
                    <span>
                      kgCO<sub>2</sub>
                    </span>
                  }
                  value={counters?.todayEmission}
                />

                <CounterBox
                  title={"Total BEI Monthly Current Year"}
                  unit={
                    <span>
                      kwh/m<sup>2</sup>
                    </span>
                  }
                  value={counters?.monthlyTotalBEI}
                />
              </aside>
            </div>
          </FullScreen>
        </Spin>
      </div>
    </div>
  );
};

export default DashboardThree;
