import { notification, Spin } from "antd";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { api, endpoint } from "../../api";

export const EditSiteDocumentURL = (props) => {
  const { show, getUrls, onHide, editData } = props;
  const [urlData, setUrlData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onInputChange = (e) => {
    setUrlData({
      ...urlData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { message } = await api.put(
        `${endpoint.site_url}/${editData._id}`,
        urlData
      );
      notification.success({
        message,
        placement: "bottomLeft",
      });
      getUrls();
      onHide();
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setUrlData({
      name: editData?.name,
      media: editData?.media,
    });
  }, [editData]);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h4 className="text-center">Update URL</h4>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                URL Name
              </label>
              <input
                type="text"
                name="name"
                value={urlData?.name}
                onChange={onInputChange}
                className="form-control"
                id="name"
                placeholder="Enter diagram name"
                required
              />
            </div>
            <div className="mb-2">
              <label htmlFor="media" className="form-label">
                URL Address
              </label>
              <input
                type="text"
                name="media"
                value={urlData?.media}
                onChange={onInputChange}
                className="form-control"
                id="media"
                placeholder="URL Address"
                required
              />
            </div>
            <div className="float-end">
              <button type="submit" className="btn btn-success me-2">
                Update
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onHide}
              >
                Cancel
              </button>
            </div>
          </form>
        </Spin>
      </Modal.Body>
    </Modal>
  );
};
