import React from "react";

export const SectionHeader = ({
  text,
  className,
  showUnderline = true,
  small = true,
}) => (
  <div className={className}>
    {small ? <h5>{text}</h5> : <h4>{text}</h4>}
    {showUnderline ? <hr className="mt-0" /> : ""}
  </div>
);
