import { notification, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { api, endpoint } from "../../../api";
import BuildingTypeTable from "./BuildingTypeTable";

const BuildingBackgroundTypes = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isListLoading, setListLoading] = useState(false);
  const [isCreatetLoading, setCreatetLoading] = useState(false);
  const [buildingTypes, setBuildingTypes] = useState([]);
  const [buildingTypeData, setBuildingTypeData] = useState({
    name: "",
  });
  const { name } = buildingTypeData;

  const onInputChange = (e) => {
    setBuildingTypeData({
      ...buildingTypeData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreatetLoading(true);
    try {
      const { message } = await api.post(endpoint.create_building_type, buildingTypeData);
      notification.success({ message, placement: "bottomLeft" });
      setBuildingTypeData({ name: "" });
      getBuildingTypes();
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setCreatetLoading(false);
    e.target.reset();
  };

  const getBuildingTypes = async () => {
    setListLoading(true);
    try {
      let { results, count } = await api.get(endpoint.get_building_types, {
        params: { limit, page, sortBy: "createdAt", orderBy: "DESC" },
      });
      results = results.map((item, idx) => {
        item.sl = (page - 1) * limit + idx + 1;
        return item;
      });
      setBuildingTypes(results);
      setTotal(count || 0);
    } catch (error) {}
    setListLoading(false);
  };

  useEffect(() => {
    document.title = "SEDA - Manage Building Background";
    getBuildingTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  return (
    <div className="card p-3 mb-3">
      <h3>Manage Building Background Types</h3>
      <div className="row mt-3">
        <div className="col-md-6">
          <Spin spinning={isCreatetLoading}>
            <h4 className="mb-2">Add New Building Type</h4>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={onInputChange}
                  className="form-control"
                  id="name"
                  placeholder="Enter a name"
                  required
                />
              </div>
              <div className="float-end">
                <button type="submit" className="btn btn-success me-2">
                  Create
                </button>
              </div>
            </form>
          </Spin>
        </div>
        <div className="col-md-6">
          <Spin spinning={isListLoading}>
            <BuildingTypeTable
              data={buildingTypes}
              getBuildingTypes={getBuildingTypes}
              listLoading={isListLoading}
              limit={limit}
              total={total}
              setPage={setPage}
              setLimit={setLimit}
            />
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default BuildingBackgroundTypes;
