import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "Dashboard",
  initialState: {
    1: {
      _id: null,
      baseLoad: 0,
    },
    2: {
      _id: null,
      baseLoad: 0,
    },
    3: {
      _id: null,
      baseLoad: 0,
    },
    4: {
      _id: null,
      backgroundImageFile: null,
      backgroundImage: null,
      cards: [],
    },
  },
  reducers: {
    setDashboardDetails: (state, action) => {
      const dashboardType = action.payload?.dashboardType || 1;
      state[dashboardType] = action.payload;
    },
    updateDashboardDetails: (state, action) => {
      const dashboardType = action.payload?.dashboardType || 1;
      state[dashboardType] = {
        ...state[dashboardType],
        ...action.payload,
      };
    },
    updateDashboard4Card: (state, { payload }) => {
      const dashboardType = 4;
      state[dashboardType].cards = Array.from(state[dashboardType].cards).map((item) => {
        if (item._id === payload._id) {
          item = { ...item, ...payload };
        }
        return item;
      });
    },
  },
});
export const { updateDashboardDetails, updateDashboard4Card, setDashboardDetails } = dashboardSlice.actions;

export default dashboardSlice;
