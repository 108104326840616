import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import {
  setCurrentSite,
  setSiteDetails,
  setCurrentDevice,
  setUserDetails,
  setIsLogged,
  setCurrentDashboard,
  setcurrentDeviceList,
} from "../../redux/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BsFillSunFill, BsMoonFill } from "react-icons/bs";
import { api, endpoint } from "../../api";
import { setDashboardDetails } from "../../redux/dashboardSlice";

const Header = ({ handle, isDark, setIsDark }) => {
  const [dashboardList, setDashboardList] = useState([]);
  const { userDetails, userSites, currentSite, currentDashboardtype, currentDeviceList, currentDevice } = useSelector(
    (state) => state?.user || {}
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const getDashboardList = async () => {
    try {
      const { results } = await api.get(endpoint.dashboard_list);
      setDashboardList(results);
      if (results.length > 0) {
        dispatch(setCurrentDashboard(results?.[0]?.dashboardType));
      }
    } catch (error) {}
  };

  const getDeviceList = async (locationId) => {
    try {
      const response = await axios.get(`/device`, {
        withCredentials: true,
        params: { site: locationId },
      });
      dispatch(setcurrentDeviceList(response.data?.results));
      dispatch(setCurrentDevice(response.data?.results?.[0]));
    } catch (error) {
      dispatch(setcurrentDeviceList([]));
      dispatch(setCurrentDevice({}));
    }
  };

  const getSiteLocationList = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      getDeviceList(response.data?.[0]?._id);
      dispatch(setSiteDetails(response.data));
      dispatch(setCurrentSite(response.data?.[0]));
    } catch (error) {}
  };

  const handleSiteChange = async (e) => {
    try {
      const locationId = e.target.value;
      getDeviceList(locationId);
      const response = await axios.get(`/site-location/` + locationId, {
        withCredentials: true,
      });
      dispatch(setCurrentDevice({}));
      dispatch(setCurrentSite(response.data));
    } catch (error) {
      dispatch(setCurrentSite([]));
    }
  };

  const handleDeviceChange = async (e) => {
    try {
      const deviceId = e.target.value;
      const response = await axios.get(`/device/` + deviceId, {
        withCredentials: true,
      });
      dispatch(setCurrentDevice(response.data.result));
    } catch (error) {
      dispatch(setCurrentDevice({}));
    }
  };

  const handleDashboardChange = async (e) => {
    dispatch(setCurrentDashboard(parseInt(e.target.value)));
  };

  useEffect(() => {
    if (location.pathname === "/") {
      if (currentDashboardtype) {
        const dashboard = [...(dashboardList ? dashboardList : [])].find(
          (d) => Number(d.dashboardType) === Number(currentDashboardtype)
        );
        dispatch(setDashboardDetails(dashboard));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDashboardtype, location.pathname, dashboardList]);

  const logOut = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout.",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`/user/logout`, {
            withCredentials: true,
          })
          .then((res) => {
            dispatch(setIsLogged(false));
            dispatch(setUserDetails({}));
            dispatch(setCurrentSite({}));
            dispatch(setSiteDetails({}));
            dispatch(setCurrentDevice({}));
            window.localStorage.clear();
            navigate("/");
          })
          .catch((err) => {});
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  let darkHandler = () => {
    setIsDark(!isDark);
    localStorage.setItem("isDark", !isDark);
  };

  let background = "/images/bg-3.jpg";

  useEffect(() => {
    getDashboardList();
    getSiteLocationList();
    if (currentSite?._id) getDeviceList(currentSite?._id);
  }, [location.pathname]);

  return (
    <div className="header" style={{ backgroundImage: `url(${background})`, backgroundSize: "cover" }}>
      <div className="container-fluid py-2 text-white">
        <div className="row d-flex align-items-center">
          <div className="col-sm-12 col-md-1">
            <div className="d-flex justify-content-center justify-content-lg-end  py-0 py-sm-3">
              <img src="/images/logo.png" alt="SEDA logo" style={{ maxHeight: "100px" }} className="img-fluid" />
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <h5 className="mt-3">Welcome, {userDetails?.name}</h5>
            <h6 className="text-capitalize">
              {userDetails?.role === "superAdmin" ? "Super Admin" : userDetails?.role}
            </h6>
            <p>{moment(new Date()).format("DD/MM/YYYY hh:mm A")}</p>
          </div>
          <div className="col-md-2">
            {location.pathname === "/" && (
              <select
                className="form-select bg-success border-0 text-white"
                id="site-dashboard"
                value={currentDashboardtype}
                name="dashboard"
                onChange={handleDashboardChange}
                aria-label="Select Dashboard"
              >
                {Array.isArray(dashboardList) &&
                  dashboardList.map(({ dashboardType, name }) => (
                    <option key={dashboardType} value={dashboardType}>
                      {name}
                    </option>
                  ))}
              </select>
            )}
          </div>
          <div className="col-md-2">
            {location.pathname === "/" && (
              <select
                className="form-select bg-success border-0 text-white"
                id="site-locations"
                name="siteLocations"
                onChange={handleSiteChange}
                aria-label="Select an admin"
                value={currentSite?._id || ""}
              >
                <option disabled label="Select Site" value={""}></option>
                {Array.isArray(userSites) &&
                  userSites.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
          <div className="col-md-2">
            <div className="d-flex">
              {location.pathname === "/" && (
                <select
                  className="form-select bg-success border-0 text-white me-3"
                  name="device"
                  onChange={handleDeviceChange}
                  value={currentDevice?._id || ""}
                >
                  <option disabled value={""}>
                    Select Device
                  </option>
                  {currentDeviceList?.length &&
                    currentDeviceList.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              )}
            </div>
          </div>
          <div className="col-md-2 d-flex justify-content-end align-items-center">
            {location.pathname === "/" && (
              <button className="btn btn-warning me-5" onClick={handle.enter}>
                Display
              </button>
            )}
            <div>
              <img
                src={userDetails?.avatar}
                alt=""
                className="img-fluid rounded-circle avater_profile"
                style={{ height: "60px", width: "60px" }}
              />
              <div className="text-center mt-2">
                <button className="btn btn-danger btn-sm" onClick={() => logOut()}>
                  Logout
                </button>
              </div>
            </div>

            <div className="ms-3 fs-5" onClick={darkHandler} style={{ cursor: "pointer", userSelect: "none" }}>
              {!!isDark ? <BsMoonFill /> : <BsFillSunFill />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

// const getSiteLocations = async (userDetails) => {
//   if (userDetails?.role === "superAdmin") {
//     console.log("sdfljsad;f las;dfl jas;dfj alsdjf l;adsjflk sajfl");

//     const response = await axios.get(`/site-location`, { withCredentials: true });
//     if (response) {
//       dispatch(setSiteDetails(response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))));
//     }
//   } else if (userDetails.role === "admin") {
//     const response = await axios.get(
//       `/site-location/admin-sites/` + userDetails._id,
//       { withCredentials: true }
//     );
//     if (response) {
//       dispatch(setSiteDetails(response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))));
//     }
//   } else if (userDetails?.role === "installer") {
//     const response = await axios.get(
//       `/site-location/installer-sites/` + userDetails._id,
//       { withCredentials: true }
//     );
//     if (response) {
//       dispatch(setSiteDetails(response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))));
//     }
//   }
// };
