import { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

export const FormulaOperation = ({
  closeable = false,
  onClose = () => {},
  onChange = () => {},
  operation = { option: "", value: "" },
  parameters = [],
  formulas = [],
  disabled = false,
  width = "150px",
}) => {
  const [option, setOption] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    setOption(operation.option);
    setValue(operation.value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation]);

  const onChangeOption = (e) => {
    setOption(e.target.value);

    if (["time", "area", "year", "month"].includes(e.target.value)) {
      setValue(e.target.value);
      onChange({
        option: e.target.value,
        value: e.target.value,
        parameter: undefined,
        formula: undefined,
      });
    } else {
      setValue("");
      onChange({
        option: e.target.value,
        value: "",
        parameter: undefined,
        formula: undefined,
      });
    }
  };

  return (
    <div className="d-flex flex-column gap-2 " style={{ width, float: "left", position: "relative" }}>
      {closeable && (
        <AiFillCloseCircle style={{ position: "absolute", right: -5, top: -10, cursor: "pointer" }} onClick={onClose} />
      )}
      <select
        name="selectOne"
        id=""
        className="form-select"
        value={option}
        onChange={onChangeOption}
        style={{ boxShadow: "none" }}
        disabled={disabled}
      >
        <option>Select Type</option>
        <option value="parameter">Parameter</option>
        <option value="operator">Operator</option>
        <option value="time">Time</option>
        <option value="year">Year</option>
        {/* <option value="month">Month</option> */}
        <option value="area">Area</option>
        <option value="constant">Constant</option>
        <option value="text">Text</option>
      </select>

      <div style={{ width: "100%" }}>
        {(() => {
          switch (option) {
            case "parameter":
              return (
                <select
                  name="option"
                  id="option"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange({ value: e.target.value, parameter: e.target.selectedOptions[0].id });
                  }}
                  className="form-select"
                  style={{ boxShadow: "none" }}
                  disabled={disabled}
                >
                  <option>Select Parameter</option>
                  {parameters?.length > 0 &&
                    parameters.map((item, index) => (
                      <option value={item.name} key={index} id={item._id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              );
            case "operator":
              return (
                <select
                  name="option"
                  id="option"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange({ value: e.target.value });
                  }}
                  className="form-select"
                  style={{ boxShadow: "none" }}
                  disabled={disabled}
                >
                  <option>Select Operator</option>
                  <option value="+">+</option>
                  <option value="-">-</option>
                  <option value="x">x</option>
                  <option value="/">/</option>
                  <option value="(">(</option>
                  <option value=")">)</option>
                  <option value="=">=</option>
                </select>
              );

            case "time":
            case "area":
            case "year":
            case "month":
              return (
                <input
                  type="text"
                  name="option"
                  id="option"
                  value={option}
                  className="form-control text-capitalize"
                  disabled
                  style={{ boxShadow: "none" }}
                />
              );
            default:
              return (
                <input
                  type="text"
                  name="value"
                  id="value"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange({ value: e.target.value });
                  }}
                  className="form-control"
                  placeholder="Enter a value"
                  style={{ boxShadow: "none" }}
                  disabled={disabled}
                />
              );
          }
        })()}
      </div>
    </div>
  );
};
