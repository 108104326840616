import React, { useEffect, useState } from "react";
import { api } from "../../api";
import { useGraphType } from "../../hooks/useGraphType";
import { useSystemParameters, useGraphSettings } from "../../hooks";
import { SectionHeader } from "./SelectedDashboardSettings/SectionHeader";
import { Spin } from "antd";

const GraphSetting = () => {
  const { data, loading } = useSystemParameters();
  const { data: graphs, loading: graphLoading } = useGraphSettings();
  const graphTypes = useGraphType();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [state, setState] = useState({});

  useEffect(() => {
    if (graphs?.length > 0) {
      const obj = {};
      for (const item of graphs) {
        Object.assign(obj, { [item.type]: item.parameter });
      }
      setState(obj);
    }
  }, [graphs]);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitLoading(true);

    const payload = Object.entries(state).map(([key, value]) => ({ type: key, parameter: value }));

    api
      .put("/dashboard/update-graph-setting", payload)
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  console.log(graphs, state);

  return (
    <div className="selectedDashboardSettings border-success">
      <SectionHeader text="Assign System Parameter On Graph" small={false} className="mb-4" />
      <Spin spinning={submitLoading}>
        <form onSubmit={handleSubmit}>
          <div className="row">
            {graphTypes?.map(({ name, value }) => {
              return (
                <div className="col-md-3" key={`graphType-${value}`}>
                  <label htmlFor="to1" className="form-label">
                    {name}
                  </label>
                  <select name={value} className="form-select" value={state[value]} onChange={handleChange} required>
                    {loading || graphLoading ? (
                      <option value="">Loading...</option>
                    ) : (
                      <option value=""> Select System Parameter</option>
                    )}
                    {data?.results?.length > 0 &&
                      data?.results.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name} ({item.value})
                        </option>
                      ))}
                  </select>
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-end">
            <button className="btn btn-success " type="submit" style={{ marginLeft: "auto", marginTop: 20 }}>
              Update
            </button>
          </div>
        </form>
      </Spin>
    </div>
  );
};

export default GraphSetting;
